import React from 'react';
import GridItem from './GridItem';

export default ({ data }) => {
  return (
    <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
      {data.map(item => (
        <GridItem key={item} data={item} />
      ))}
    </div>
  );
};
