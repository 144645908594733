import React from 'react';

/* framer-motion and useInView here are used to animate the sections in when we reach them in the viewport
 */
import { motion } from 'framer-motion';
import useInView from 'use-in-view';

function AnimationReveal({ disabled, children }) {
  if (disabled) {
    return <>{children}</>;
  }

  if (!Array.isArray(children)) children = [children];

  const childrenWithAnimation = children.map((child, i) => {
    return (
      <AnimatedComponent key={i}>
        {child}
      </AnimatedComponent>
    );
  });
  return <>{childrenWithAnimation}</>;
}

function AnimatedComponent({ children }) {
  const [ref, isInView] = useInView(400);

  return (
    <motion.section
      ref={ref}
      style={{
        transform: isInView ? "none" : "translateY(50px)",
        opacity: isInView ? 1 : 0,
        transition: "all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s"
      }}
    >
      {children}
    </motion.section>
  );
}

export default props => (
  <div style={{ overflowX: 'hidden', overflowY: 'hidden', zIndex: 0 }}>
    <AnimationReveal {...props} />
  </div>
);
