import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import dataHandlingSrcHun from '../assets/Adatkezelési_tájékoztató_ADB-Pro Kft_weboldal.pdf';

import CookieIcon from '../icons/CookieIcon';

const CookiePopup = () => {
    const [isCookie, setIsCookie] = useState(false);
    //const intl = useIntl();
    // const dataHandlingSrc = intl.locale == 'hu' ? dataHandlingSrcHun : dataHandlingSrcEn;
    const dataHandlingSrc = dataHandlingSrcHun;
    
    useEffect(() => {
        if(document.cookie !== ''){
            setIsCookie(true)
        }
    }, [isCookie, setIsCookie]);

    function addCookie() {
        document.cookie = "cookies=yes; max-age=" + (365 * 24 * 60 * 60);
        setIsCookie(true)
    }

    return (
        <AnimatePresence>
        {
            !isCookie && 
            <motion.div
                className="w-11/12 md:w-10/12 lg:w-9/12 custom-box-shadow pt-6 pb-8 px-10 bg-white rounded-3xl fixed flex flex-col-reverse md:flex-row justify-center items-center"
                initial={{ bottom: "-500px" }}
                animate={{ bottom: "15px" }}
                exit={{ 
                    bottom: "-500px",
                    transition: { 
                        ease: 'easeOut', 
                        delay: 0.1,
                        duration: 1,
                    }
                 }}
                transition={{ 
                    bottom: { 
                        type: "spring",
                        stiffness: 80,
                        damping: 12.5,
                        delay: 1,
                        duration: 0.2
                    } 
                }}
                style={{
                    left: "50%",
                    bottom: "15px",
                    transform: "translateX(-50%)", 
                    zIndex:"10000"
                }}
            >
                    <div className="flex flex-col justify-center items-center">
                        <p className="pb-4 text-justify">
                            <FormattedMessage id={'cookieText'} values={{ a: chunks => <a className="underline text-primary" target="_blank" href={dataHandlingSrc}>{chunks}</a> }} />
                        </p>
                        <button className="py-2 px-2 text-gray-100 bg-primary rounded bold w-1/2 md:w-1/4 lg:w-2/12"
                                onClick={addCookie} >
                            <FormattedMessage id={'cookieAccept'} />
                        </button>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <CookieIcon className={"w-12 h-12 md:w-16 md:h-16 mb-4 md:mb-0 md:ml-2"} />
                    </div>
            </motion.div>
        }
        </AnimatePresence>
    );
};

export default CookiePopup;
