export default [
    { 
        text: "informationSecurity.paragraph2.text", 
        points: [
            { text: "informationSecurity.paragraph2.points.0"  },
            { text: "informationSecurity.paragraph2.points.1"  },
            { text: "informationSecurity.paragraph2.points.2"  },
            { text: "informationSecurity.paragraph2.points.3"  },
        ]
    },
    { 
        text: "informationSecurity.paragraph3.text", 
        points: [
            { text: "informationSecurity.paragraph3.points.0"  },
            { text: "informationSecurity.paragraph3.points.1"  },
            { text: "informationSecurity.paragraph3.points.2"  },
            { text: "informationSecurity.paragraph3.points.3"  },
            { text: "informationSecurity.paragraph3.points.4"  },
        ]
    },
    { 
        text: "informationSecurity.paragraph4.text", 
        points: [
            { text: "informationSecurity.paragraph4.points.0"  },
            { text: "informationSecurity.paragraph4.points.1"  },
            { text: "informationSecurity.paragraph4.points.2"  },
            { 
                text: "informationSecurity.paragraph4.points.3.text",
                points: [
                    { text: "informationSecurity.paragraph4.points.3.points.0"  },
                    { text: "informationSecurity.paragraph4.points.3.points.1"  },
                    { text: "informationSecurity.paragraph4.points.3.points.2"  },
                ]
            },
            { text: "informationSecurity.paragraph4.points.4"  },
        ]
    },
    { 
        text: "informationSecurity.paragraph5.text" }
];
  