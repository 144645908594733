import React from 'react';
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl';
import GlobeIcon from '../icons/GlobeIcon';

const languageName = {
  hu: 'hu',
  en: 'en'
};

const Language = () => {
  return (
    <div className="flex flex-row">
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => {
            if (language != currentLocale) {
              return (
                <div
                  key={language}
                  className="flex flex-row cursor-pointer hover:opacity-75"
                  onClick={() => changeLocale(language)}
                >
                  <div className="w-6">
                    <GlobeIcon />
                  </div>
                  <div className="pl-2">{languageName[language]}</div>
                </div>
              );
            }
          })
        }
      </IntlContextConsumer>
    </div>
  );
};

export default Language;
