import { AnimatePresence, motion, useAnimatedState } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// eslint-disable-next-line react/prop-types

export default function ScrollUpButton() {
    const [seen, setSeen] = useState(false);

    const variants = {
        initial: {
            bottom: "-300px",
        },
        animate: {
            bottom: "15px",
            transition: {
                bottom: { 
                    type: "spring",
                    stiffness: 80,
                    damping: 12.5,
                    duration: 0.2
                } 
            }
        },
        exit: {
            bottom: "-300px",
            transition: { 
                ease: 'easeOut', 
                duration: 1,
            }
        }
      };
  
    // detect whether user has scrolled the page down by 10px
    useEffect(() => {  
      const scrollHandler = () => {
        if(window.pageYOffset > (window.innerHeight * 0.2)){
            setSeen(true);
        }
        else {
            setSeen(false);
        }
      };
      window.addEventListener('scroll', scrollHandler);
      return () => window.removeEventListener('scroll', scrollHandler);
    }, [seen]);

    return (
        <AnimatePresence> 
            <motion.div
                className="w-14 fixed flex md:flex-row justify-center items-center"
                variants={variants}
                initial="initial"
                animate={seen ? "animate" : "exit"}
                style={{
                    right: "15px",
                    bottom: "15px",
                    zIndex:"5000"
                }}
            >
                <AnchorLink href="#hero" >
                    <button className="text-gray-100 bg-primary rounded-full bold w-10 h-10">
                    <span className="text-lg">▲</span>
                    </button>
                </AnchorLink>
                
            </motion.div>
        </AnimatePresence>
    )
}
