import React from 'react';

const sizes = {
  default: `py-3 px-8`,
  lg: `py-4 px-12`,
  xl: `lg:py-5 lg:px-16 py-4 px-12 text-lg`
};
// eslint-disable-next-line react/prop-types
const Button = ({ children, className = '', size }) => {
  return (
    <button
      type="button"
      className={`
        ${sizes[size] || sizes.default}
        ${className}
        rounded
        bold
    `}
    >
      {children}
    </button>
  );
};

export default Button;
