import React from 'react';
import CustomerCard from './CustomerCard';

export default ({ data }) => {
  return (
    <div className="flex md:flex-row flex-col flex-wrap pt-16">
      {data.map(reference => (
        <div key={reference.title} className="flex-1 p-3">
          <CustomerCard customer={reference} />
        </div>
      ))}
    </div>
  );
};
