import React from 'react';

export default function SvgAddContent() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 1003.67004 595.29205">
            <path d="M329.48148,708.62523l16.11882-5.113a54.56124,54.56124,0,0,0-.3883-26.249c-6.47576,14.3987-25.47463,17.92647-36.29963,29.419a32.81868,32.81868,0,0,0-8.39531,27.17926l-3.39124,11.504a54.99381,54.99381,0,0,0,40.024-22.76717,53.12081,53.12081,0,0,0,6.22435-11.78347C336.50013,710.12665,329.48148,708.62523,329.48148,708.62523Z" transform="translate(-98.16498 -152.35398)" fill="#f2f2f2"/>
            <path d="M900.70749,708.62523l-16.11882-5.113a54.56124,54.56124,0,0,1,.38831-26.249c6.47575,14.3987,25.47463,17.92647,36.29963,29.419a32.81867,32.81867,0,0,1,8.3953,27.17926l3.39124,11.504a54.99379,54.99379,0,0,1-40.024-22.76717,53.12085,53.12085,0,0,1-6.22436-11.78347C893.68884,710.12665,900.70749,708.62523,900.70749,708.62523Z" transform="translate(-98.16498 -152.35398)" fill="#f2f2f2"/>
            <path d="M312.2575,687.35271l12.14673-11.765a54.56117,54.56117,0,0,0-12.05412-23.32081c.62547,15.7755-14.806,27.40615-19.36941,42.52016a32.81864,32.81864,0,0,0,4.60728,28.07073l2.09524,11.809a54.99375,54.99375,0,0,0,25.66946-38.22753,53.12068,53.12068,0,0,0,.31586-13.32264C319.2091,685.56636,312.2575,687.35271,312.2575,687.35271Z" transform="translate(-98.16498 -152.35398)" fill="#f2f2f2"/>
            <path d="M344.73112,468.30409a9.15747,9.15747,0,0,1-10.36538-9.47276L275.81867,399.427l16.344-11.37852,52.41058,61.97045a9.20709,9.20709,0,0,1,.15791,18.28517Z" transform="translate(-98.16498 -152.35398)" fill="#ffb6b6"/>
            <path d="M257.80724,373.73185s-4.14942,2.48706,2.757,10.06045c5.52909,6.06307,29.65661,35.21406,39.28721,41.30026a7.194,7.194,0,0,0,2.82716,8.35423l14.807-18.47782c-.84587-5.29779-4.41781-5.0272-6.42436-4.38385l-9.29-11.54758-21.04879-35.30566-19.94773,8.149Z" transform="translate(-98.16498 -152.35398)" fill="#257a8f"/><polygon points="113.405 575.335 124.443 575.335 129.696 532.761 113.405 532.761 113.405 575.335" fill="#ffb6b6"/>
            <path d="M207.968,720.12939l17.634-1.05251V726.632l16.7651,11.57857a4.71924,4.71924,0,0,1-2.68163,8.60273H218.69157l-3.61862-7.47321-1.41288,7.47321h-7.91554Z" transform="translate(-98.16498 -152.35398)" fill="#2f2e41"/><polygon points="175.405 575.335 186.443 575.335 191.696 532.761 175.405 532.761 175.405 575.335" fill="#ffb6b6"/>
            <path d="M269.968,720.12939l17.634-1.05251V726.632l16.7651,11.57857a4.71924,4.71924,0,0,1-2.68163,8.60273H280.69157l-3.61862-7.47321-1.41288,7.47321h-7.91554Z" transform="translate(-98.16498 -152.35398)" fill="#2f2e41"/>
            <path d="M206.80793,690.97563h.12l2.12275-69.22333a18.10111,18.10111,0,0,1,.23751-17.1052l.30391-.55014-.788-3.65272a23.26651,23.26651,0,0,1,1.32959-14.00271L212.114,521.869c-7.07488-45.85118,26.64191-75.75358,26.64191-75.75358l-6.16142-25.91925,61-6-4.33037,31.91925,10.84353,37.2558c7.16852,69.84052,5.8678,137.89795-5.50763,203.83417l.31012.15682c1.11665.38545,1.59558,1.93234,1.07,3.45519s-1.85694,2.44491-2.9736,2.05946l-26.67413-.92778c-1.11666-.38536-1.59576-1.93234-1.07-3.45519.52557-1.52285,1.85694-2.44482,2.97359-2.05946l1.81368.91735-3.86794-66.7979.42925-12.20105-.88431-13.84185L261.638,532.25042l-15.47616,76.563-15.80813,82.93272a2.908,2.908,0,0,1-1.96091,5.0635H206.80793a2.917,2.917,0,0,1,0-5.834Z" transform="translate(-98.16498 -152.35398)" fill="#2f2e41"/>
            <path d="M791.75025,434.06727h-377.269A47.8986,47.8986,0,0,1,366.637,386.22255v-162.168a47.898,47.898,0,0,1,47.70752-47.84473c58.59131-16.21777,120.80371-24.23926,184.937-23.8418,61.0249.37842,125.81836,8.39991,192.5835,23.8418a47.90014,47.90014,0,0,1,47.72949,47.84473v162.168A47.8986,47.8986,0,0,1,791.75025,434.06727Zm-377.269-255.85742A45.89631,45.89631,0,0,0,368.637,224.05458v162.168a45.896,45.896,0,0,0,45.84424,45.84472h377.269a45.896,45.896,0,0,0,45.84424-45.84472v-162.168a45.89631,45.89631,0,0,0-45.84424-45.84473l-.22559-.02588c-138.15918-31.96973-261.40429-31.97412-376.77636-.01025Z" transform="translate(-98.16498 -152.35398)" fill="#e6e6e6"/>
            <path d="M407.82665,391.74492a1.13181,1.13181,0,0,1-1.13211-1.13211V219.66421a1.13211,1.13211,0,0,1,2.26422,0v170.9486A1.13181,1.13181,0,0,1,407.82665,391.74492Z" transform="translate(-98.16498 -152.35398)" fill="#e6e6e6"/>
            <path d="M506.82665,391.74492a1.13181,1.13181,0,0,1-1.13211-1.13211V219.66421a1.13211,1.13211,0,0,1,2.26422,0v170.9486A1.13181,1.13181,0,0,1,506.82665,391.74492Z" transform="translate(-98.16498 -152.35398)" fill="#e6e6e6"/>
            <path d="M605.82665,391.74492a1.13181,1.13181,0,0,1-1.13211-1.13211V219.66421a1.13211,1.13211,0,0,1,2.26422,0v170.9486A1.13181,1.13181,0,0,1,605.82665,391.74492Z" transform="translate(-98.16498 -152.35398)" fill="#e6e6e6"/>
            <path d="M704.82665,391.74492a1.13181,1.13181,0,0,1-1.13211-1.13211V219.66421a1.13211,1.13211,0,0,1,2.26422,0v170.9486A1.13181,1.13181,0,0,1,704.82665,391.74492Z" transform="translate(-98.16498 -152.35398)" fill="#e6e6e6"/>
            <path d="M399.0092,245.13851a1.13182,1.13182,0,0,1,1.13211-1.13211H806.08992a1.13211,1.13211,0,1,1,0,2.26422H400.14131A1.13182,1.13182,0,0,1,399.0092,245.13851Z" transform="translate(-98.16498 -152.35398)" fill="#e6e6e6"/>
            <path d="M399.0092,371.13851a1.13182,1.13182,0,0,1,1.13211-1.13211H806.08992a1.13211,1.13211,0,1,1,0,2.26422H400.14131A1.13182,1.13182,0,0,1,399.0092,371.13851Z" transform="translate(-98.16498 -152.35398)" fill="#e6e6e6"/>
            <path d="M399.0092,308.13851a1.13182,1.13182,0,0,1,1.13211-1.13211H806.08992a1.13211,1.13211,0,1,1,0,2.26422H400.14131A1.13182,1.13182,0,0,1,399.0092,308.13851Z" transform="translate(-98.16498 -152.35398)" fill="#e6e6e6"/>
            <path d="M800.6688,391.74492a1.13181,1.13181,0,0,0,1.13211-1.13211V219.66421a1.13211,1.13211,0,0,0-2.26422,0v170.9486A1.13181,1.13181,0,0,0,800.6688,391.74492Z" transform="translate(-98.16498 -152.35398)" fill="#e6e6e6"/>
            <path d="M469.57773,300.46817H441.06308a6.5142,6.5142,0,0,1-6.44483-5.59961,144.98372,144.98372,0,0,1,0-39.04736,6.51459,6.51459,0,0,1,6.33545-5.59863c8.97168-4.02149,18.6333-4.021,28.72217-.00049a6.43873,6.43873,0,0,1,4.89941,2.36279,6.58718,6.58718,0,0,1,1.35938,5.49073,87.6593,87.6593,0,0,0,0,34.53857,6.58715,6.58715,0,0,1-1.35938,5.49072A6.4375,6.4375,0,0,1,469.57773,300.46817Z" transform="translate(-98.16498 -152.35398)" fill="#257a8f"/>
            <path d="M466.5914,265.10929h-24a1.5,1.5,0,0,1,0-3h24a1.5,1.5,0,0,1,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/>
            <path d="M466.5914,277.10929h-24a1.5,1.5,0,0,1,0-3h24a1.5,1.5,0,0,1,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/>
            <path d="M466.5914,289.10929h-24a1.5,1.5,0,0,1,0-3h24a1.5,1.5,0,0,1,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/>
            <path d="M674.57773,366.46817H646.06308a6.5142,6.5142,0,0,1-6.44483-5.59961,144.98372,144.98372,0,0,1,0-39.04736,6.51459,6.51459,0,0,1,6.33545-5.59863c8.97168-4.02149,18.6333-4.021,28.72217-.00049a6.43873,6.43873,0,0,1,4.89941,2.36279,6.58718,6.58718,0,0,1,1.35938,5.49073,87.6593,87.6593,0,0,0,0,34.53857,6.58715,6.58715,0,0,1-1.35938,5.49072A6.4375,6.4375,0,0,1,674.57773,366.46817Z" transform="translate(-98.16498 -152.35398)" fill="#257a8f"/>
            <path d="M671.5914,331.10929h-24a1.5,1.5,0,0,1,0-3h24a1.5,1.5,0,0,1,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/>
            <path d="M671.5914,343.10929h-24a1.5,1.5,0,0,1,0-3h24a1.5,1.5,0,0,1,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/>
            <path d="M761.57773,300.46817H733.06308a6.5142,6.5142,0,0,1-6.44483-5.59961,144.98372,144.98372,0,0,1,0-39.04736,6.51459,6.51459,0,0,1,6.33545-5.59863c8.97168-4.02149,18.6333-4.021,28.72217-.00049a6.43873,6.43873,0,0,1,4.89941,2.36279,6.58718,6.58718,0,0,1,1.35938,5.49073,87.6593,87.6593,0,0,0,0,34.53857,6.58715,6.58715,0,0,1-1.35938,5.49072A6.4375,6.4375,0,0,1,761.57773,300.46817Z" transform="translate(-98.16498 -152.35398)" fill="#fd6584"/>
            <path d="M758.5914,277.10929h-24a1.5,1.5,0,0,1,0-3h24a1.5,1.5,0,0,1,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/>
            <path d="M758.5914,289.10929h-24a1.5,1.5,0,0,1,0-3h24a1.5,1.5,0,0,1,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/>
            <path d="M280.26118,296.05448c-5.67341-8.69388-14.93655-15.526-25.25254-16.10331-10.31656-.57719-22.71441,8.20792-27.806,17.24763-9.31354,16.53543-9.43575,35.49692,3.39187,46.99738,1.742-.59089,8.98806-1.438,10.759-2.00834l1.123-2.028,1.38549,1.23286c15.8527,1.58167,20.73257-9.19652,46.09809-15.99231C288.47673,315.121,285.93458,304.74835,280.26118,296.05448Z" transform="translate(-98.16498 -152.35398)" fill="#2f2e41"/><circle cx="160.31979" cy="163.68554" r="20.96357" fill="#ffb7b7"/>
            <path d="M281.71378,354.99041l-11.99626-7.19776L245.725,345.3934l-21.206,10.7985.21253,32.98785,13.4529,34.21415,52.41005,10.80225,6.11462-30.0683s15.59514-20.94623-1.19962-32.94249Z" transform="translate(-98.16498 -152.35398)" fill="#257a8f"/>
            <path d="M266.32257,284.20223a26.33086,26.33086,0,0,0-32.31646,32.95909c12.76778,1.01912,25.38817-.173,35.33669-9.20645l.88525-7.74857,3.63,6.48434q5.49179-1.54494,11.00067-3.10913A26.88525,26.88525,0,0,0,266.32257,284.20223Z" transform="translate(-98.16498 -152.35398)" fill="#2f2e41"/>
            <path d="M230.36287,497.4208a9.15744,9.15744,0,0,1-2.03055-13.89427l-7.736-83.04693,19.85557,1.53355,1.36071,81.15019a9.20709,9.20709,0,0,1-11.44977,14.25746Z" transform="translate(-98.16498 -152.35398)" fill="#ffb6b6"/>
            <path d="M225.25438,356.52288s-4.78672-.70039-4.23229,9.5342c.44387,8.19357-1.66577,58.6967,1.9391,69.50388a7.194,7.194,0,0,0-3.09812,8.25757l23.15846-4.93587c2.69784-4.63721,2.13891-9.3985.17815-10.17025l.11518-14.82014,5.11817-48.85069-19.7096-8.07387Z" transform="translate(-98.16498 -152.35398)" fill="#257a8f"/>
            <path d="M343.61369,505.10489a6.58717,6.58717,0,0,1-1.35937-5.49072,87.65967,87.65967,0,0,0,0-34.53857,6.5872,6.5872,0,0,1,1.35937-5.49073,6.43876,6.43876,0,0,1,4.89942-2.36279c10.08886-4.02051,19.75048-4.021,28.72216.00049a6.51457,6.51457,0,0,1,6.33545,5.59863,144.9829,144.9829,0,0,1,0,39.04736,6.5142,6.5142,0,0,1-6.44482,5.59961H348.61125A6.43753,6.43753,0,0,1,343.61369,505.10489Z" transform="translate(-98.16498 -152.35398)" fill="#257a8f"/>
            <path d="M351.59758,472.10929h24a1.5,1.5,0,0,0,0-3h-24a1.5,1.5,0,0,0,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/>
            <path d="M351.59758,484.10929h24a1.5,1.5,0,0,0,0-3h-24a1.5,1.5,0,0,0,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/>
            <path d="M351.59758,496.10929h24a1.5,1.5,0,0,0,0-3h-24a1.5,1.5,0,0,0,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/><polygon points="913.091 582.537 900.792 582.537 894.941 535.096 913.093 535.097 913.091 582.537" fill="#a0616a"/>
            <path d="M1014.39281,746.81334l-39.658-.00147v-.50161a15.43684,15.43684,0,0,1,15.436-15.43577h.001l7.24406-5.49573,13.51582,5.49657,3.4619.00014Z" transform="translate(-98.16498 -152.35398)" fill="#2f2e41"/><polygon points="855.951 582.537 843.652 582.537 837.801 535.096 855.953 535.097 855.951 582.537" fill="#a0616a"/>
            <path d="M957.25254,746.81334l-39.658-.00147v-.50161a15.43684,15.43684,0,0,1,15.436-15.43577h.001l7.24406-5.49573,13.51582,5.49657,3.4619.00014Z" transform="translate(-98.16498 -152.35398)" fill="#2f2e41"/><polygon points="922.881 278.806 906.621 328.803 855.363 327.123 871.114 278.806 922.881 278.806" fill="#2f2e41"/>
            <path d="M944.28457,475.275l96.63429,2.5209-1.6806,47.05669s1.6806,12.60448-1.68059,15.96567-5.04179,3.36119-3.3612,9.24328-7.19877,15.48927-6.12028,16.56777,3.59939,6.96058,3.59939,6.96058l-8.403,47.897s-5.88209,83.18951-7.56269,84.87011-3.36119,0-1.68059,4.20149,3.36119,2.52089,1.68059,4.20149a61.89,61.89,0,0,0-4.20149,5.04179h-20.316s-1.53173-8.403-1.53173-9.24328-1.68059-5.88209-1.68059-6.72239,1.48541-2.3257,1.48541-2.3257a27.6452,27.6452,0,0,0,1.03548-5.237c0-1.6806,5.04179-66.38355,5.04179-66.38355l-10.08358-84.02981-24.36864,82.34921s0,72.26564-1.6806,73.94624-1.6806.8403-.8403,4.20149,4.20149,2.52089,1.6806,5.04179-3.36119-1.6806-2.5209,2.52089l.8403,4.20149-25.20894.3606s-3.36119-7.083-1.6806-9.60388,1.57976-1.83277-.47056-5.95817-2.89063-4.9657-2.05033-5.806.8403-5.30976.8403-5.30976l4.20149-80.40065s.84029-84.87011.84029-87.391a8.38157,8.38157,0,0,0-.698-3.7803v-3.42179l3.21894-12.12477Z" transform="translate(-98.16498 -152.35398)" fill="#2f2e41"/>
            <path d="M953.1077,353.01164l29.41044-10.92388,48.73729,3.3612,11.28486,5.26627a19.52661,19.52661,0,0,1,10.88279,21.55955l-14.605,72.32935,3.77638,34.8005s-61.3368,46.28827-109.23379,32.0032c0,0,.8403-13.44477,1.68059-18.48655s2.5209-5.04179,3.3612-7.56269,2.52089,0,.8403-5.04179,13.44477-40.33431,13.44477-40.33431Z" transform="translate(-98.16498 -152.35398)" fill="#e6e6e6"/><polygon points="901.43 207.051 925.405 192.565 917.545 178.39 892.075 178.39 881.985 190.613 901.43 207.051" fill="#e6e6e6"/>
            <path d="M1007.84389,478.51042a9.38339,9.38339,0,0,0,9.85743-10.48121l29.98332-14.59192-14.90785-8.8328-26.18714,15.21091a9.43427,9.43427,0,0,0,1.25424,18.695Z" transform="translate(-98.16498 -152.35398)" fill="#a0616a"/>
            <path d="M1019.623,472.453l-5.224-19.15494,28.46756-37.71412c-9.21456-11.10934-12.44815-27.51361-10.17425-51.56715a10.43723,10.43723,0,0,1,20.11751-5.37823c6.44173,18.78772,17.64831,59.22009,16.18845,61.40987-1.681,2.52172-2.25789,3.64143.3861,6.0499,3.13511,2.85611,1.47216,15.02772.04185,16.774C1068.12269,444.4634,1019.623,472.453,1019.623,472.453Z" transform="translate(-98.16498 -152.35398)" fill="#e6e6e6"/>
            <path d="M868.91222,448.75925a9.38339,9.38339,0,0,0,12.59767-6.95151l33.01414-4.6894-11.479-12.98053L873.45407,430.581a9.43428,9.43428,0,0,0-4.54185,18.17825Z" transform="translate(-98.16498 -152.35398)" fill="#a0616a"/>
            <path d="M883.07841,445.99905l5.0125-19.21136,41.50358-23.38306c3-19,4.88022-25.19494,18.83428-44.91872a10.43723,10.43723,0,0,1,20.12284,5.35825c-3.77365,19.49959-20.847,71.75885-22.95712,72.56047C943.67157,437.13494,883.07841,445.99905,883.07841,445.99905Z" transform="translate(-98.16498 -152.35398)" fill="#e6e6e6"/><circle cx="904.52032" cy="143.51739" r="26.04924" fill="#a0616a"/>
            <path d="M975.09993,276.9742c.69218-6.23,9.69118-12.46005,25.61232-16.61338s24.22787,13.84446,24.22787,13.84446c11.07563,5.53779,4.49957,34.9574,1.03837,32.18848q-.05192-.04154-.10373-.0809a20.622,20.622,0,0,1-8.237-14.07011l-.79656-7.16925c-3.646,2.79395-7.75558,5.1596-12.16668,4.45645l5.39865-5.31557q-7.67493,3.33945-15.34991,6.6789l5.08143-6.9921-.24038-.23619a25.10384,25.10384,0,0,1-8.05315,4.55989c-3.35755.96236-7.38061.44174-9.681-2.1865a7.14207,7.14207,0,0,1-1.376-2.47648c-4.17733,2.86114-5.008,8.98741-5.008,8.98741S974.40775,283.20422,975.09993,276.9742Z" transform="translate(-98.16498 -152.35398)" fill="#2f2e41"/>
            <path d="M870.57773,465.67663H842.06308a6.51421,6.51421,0,0,1-6.44483-5.59961,144.98372,144.98372,0,0,1,0-39.04736,6.51459,6.51459,0,0,1,6.33545-5.59863c8.97168-4.02149,18.6333-4.021,28.72217-.00049a6.43873,6.43873,0,0,1,4.89941,2.36279,6.58715,6.58715,0,0,1,1.35938,5.49072,87.65935,87.65935,0,0,0,0,34.53858,6.58715,6.58715,0,0,1-1.35938,5.49072A6.43753,6.43753,0,0,1,870.57773,465.67663Z" transform="translate(-98.16498 -152.35398)" fill="#3f3d56"/>
            <path d="M867.5914,430.31774h-24a1.5,1.5,0,0,1,0-3h24a1.5,1.5,0,0,1,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/>
            <path d="M867.5914,442.31774h-24a1.5,1.5,0,0,1,0-3h24a1.5,1.5,0,0,1,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/>
            <path d="M867.5914,454.31774h-24a1.5,1.5,0,0,1,0-3h24a1.5,1.5,0,0,1,0,3Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/><circle cx="650.42951" cy="53.8422" r="19" fill="#e6e6e6"/>
            <path d="M758.348,198.2776a1.53661,1.53661,0,0,0-2.12134,0,62.934,62.934,0,0,0-10.7818,11.01282q-1.15238-2.22959-2.30457-4.45923c-.88671-1.7157-3.47637-.20008-2.59039,1.51416q1.6854,3.26119,3.37079,6.52234a1.51762,1.51762,0,0,0,2.59045,0A60.74855,60.74855,0,0,1,758.348,200.39894,1.50908,1.50908,0,0,0,758.348,198.2776Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/><circle cx="557.42951" cy="53.8422" r="19" fill="#e6e6e6"/>
            <path d="M665.348,198.2776a1.53661,1.53661,0,0,0-2.12134,0,62.934,62.934,0,0,0-10.7818,11.01282q-1.15238-2.22959-2.30457-4.45923c-.88671-1.7157-3.47637-.20008-2.59039,1.51416q1.6854,3.26119,3.37079,6.52234a1.51762,1.51762,0,0,0,2.59045,0A60.74855,60.74855,0,0,1,665.348,200.39894,1.50908,1.50908,0,0,0,665.348,198.2776Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/><circle cx="361.42951" cy="51.8422" r="19" fill="#e6e6e6"/>
            <path d="M469.348,196.2776a1.53661,1.53661,0,0,0-2.12134,0,62.934,62.934,0,0,0-10.7818,11.01282q-1.15238-2.22959-2.30457-4.45923c-.88671-1.7157-3.47637-.20008-2.59039,1.51416q1.6854,3.26119,3.37079,6.52234a1.51762,1.51762,0,0,0,2.59045,0A60.74855,60.74855,0,0,1,469.348,198.39894,1.50908,1.50908,0,0,0,469.348,196.2776Z" transform="translate(-98.16498 -152.35398)" fill="#fff"/><circle cx="134.42951" cy="135.8422" r="13" fill="#2f2e41"/>
            <path d="M380.835,746.456a1.18647,1.18647,0,0,1-1.19006,1.19H99.355a1.19,1.19,0,1,1,0-2.38h280.29A1.18651,1.18651,0,0,1,380.835,746.456Z" transform="translate(-98.16498 -152.35398)" fill="#ccc"/>
            <path d="M1101.835,746.456a1.18647,1.18647,0,0,1-1.19006,1.19H820.355a1.19,1.19,0,1,1,0-2.38h280.29A1.18651,1.18651,0,0,1,1101.835,746.456Z" transform="translate(-98.16498 -152.35398)" fill="#ccc"/>
            <path d="M228.67927,275.15693c-2.25025-.626-4.59082.42218-6.691,1.44428l-7.47052,3.63556q7.75789.68069,15.51581,1.36145l.57147-1.07544C232.39509,279.02235,230.92951,275.78285,228.67927,275.15693Z" transform="translate(-98.16498 -152.35398)" fill="#2f2e41"/>
        </svg>
    );
}
