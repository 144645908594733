import React from 'react';

import SubTitle from '../SubTitle';
import Section from '../Section';
import Partners from './Partners';
import Title from '../Title';
import ReferenceList from '../ReferenceList';

import customerData from '../../data/customer-data';
import AnimationReveal from '../AnimationReveal';
import ContactUsButton from '../ContactUsButton';

export default function References() {
  return (
    <AnimationReveal>
      <Section name="referenciák">
        <div className="container mx-auto text-center">
          <div className="pb-4">
            <div className="container mx-auto text-center">
              <Title text="references.title" />
              <SubTitle text="references.subtitle" />
            </div>
            <ReferenceList data={customerData} />
          </div>
          <div>
            <Partners />
          </div>
          <div className="container mx-auto text-center">
            <ContactUsButton />
          </div>
        </div>
      </Section>
    </AnimationReveal>
  );
}
