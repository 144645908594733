import React from 'react';

import AnimationReveal from '../AnimationReveal';
import SubTitle from '../SubTitle';
import Section from '../Section';
import Title from '../Title';
import Card from '../Card';

import data from '../../data/information-security-data';
import QualityIcon from '../../icons/QualityIcon';
import iso27001Hun from '../../assets/Certop_27001_COhun.png';
import iso27001Eng from '../../assets/Certop_27001_COeng.png';
import { Link } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import ContactUsButton from '../ContactUsButton';

export default function InformationSecurity() {
  const intl = useIntl();
  const iso27001Src = intl.locale == 'hu' ? iso27001Hun : iso27001Eng;
  return (
    <AnimationReveal>
      <Section name="informationSecurity">
        <div className="container mx-auto text-center">
          <div className="container mx-auto text-center">
            <Title text="informationSecurity.title" />
            <SubTitle text="informationSecurity.subtitle" />
          </div>
          <div className="flex flex-wrap pt-16 w-full">

            <div className='px-3 pb-6 text-justify text-lg'>
                <FormattedMessage id="informationSecurity.paragraph1.text" 
                values={{
                    b: chunks => <b>{chunks}</b>,
                }}
                />
            </div>
            <div className='pb-10 w-full'>
               <div className='w-full lg:w-2/6 md:w-3/5 sm:w-2/3 mx-auto my-0'>
               <Link to="/iso">
                  <img src={iso27001Src} className="isoLogo mt-3 p-2" />
                </Link>
               </div>
            </div>

            {data.map((item, index) => (
                <div key={index} className={`flex flex-wrap w-full pb-10`}>
                    <div key={item} className='pb-10 text-justify text-lg'>
                        <FormattedMessage id={item.text} 
                        values={{
                            b: chunks => <b>{chunks}</b>,
                        }}
                        />
                    </div> 
                    {
                        item.points &&
                        <div className="flex flex-wrap pb-2 w-full">
                            {item.points.map((main, idx) => (
                                <div key={`${index}-${idx}`} className={`p-3 ${ index == 3 || (item.points.length % 2 == 1 && idx == item.points.length - 1)  ? `w-full` : `w-full lg:w-1/2 md:w-full sm:w-full`}`} style={{zIndex: idx}}>
                                    <Card className="bg-white h-full w-full py-5 px-6 lg:pr-10 rounded-lg border-2 border-gray-300">
                                        <div className="text-gray-700 text-justify h-full flex flex-col gap-y-2 lg:flex-row items-center">
                                            <QualityIcon className="w-8 h-8 flex-shrink-0 mr-4 text-primary" />
                                            <div>
                                                <FormattedMessage id={main.text} 
                                                    values={{
                                                    b: chunks => <b>{chunks}</b>,
                                                    }}
                                                />
                                                {
                                                    main.points && 
                                                    <ul className="flex flex-wrap pt-4 pb-2 pl-6 w-full">
                                                        {
                                                            main.points?.map((point, i) => (
                                                                <li key={`${index}-${idx}-${i}`} className="w-full">
                                                                    <span className="square bg-gray-700"></span>
                                                                    <FormattedMessage id={point.text} 
                                                                    values={{
                                                                    b: chunks => <b>{chunks}</b>,
                                                                    }}
                                                                    />
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                }
                                                
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            ))}
                        </div> 
                    }
                </div>
            ))}
          </div>
          <div className="container mx-auto text-center">
            <ContactUsButton />
          </div>
        </div>
      </Section>
    </AnimationReveal>
  );
}
