import React from 'react';
{
  /* <style>.cls-1{fill:#626460;}.cls-2{fill:#1d8b8f;}</style> */
}
export default function SvgWittur({ fill, fill2 }) {
  return (
    <svg id="Layer_1" viewBox="0 0 1024 1024">
      <defs></defs>
      <title>wittur</title>
      <polygon
        fill={fill}
        points="378.85 448.35 325.14 326.09 271.43 203.83 378.85 203.83 486.26 203.83 432.56 326.09 378.85 448.35"
      />
      <polygon
        fill={fill}
        points="633.03 448.35 579.32 326.09 525.61 203.83 633.03 203.83 740.45 203.83 686.74 326.09 633.03 448.35"
      />
      <polygon
        fill={fill}
        points="257.82 402.46 204.11 524.71 150.4 646.97 257.82 646.97 365.24 646.97 311.53 524.71 257.82 402.46"
      />
      <polygon
        fill={fill2}
        points="512 402.46 458.29 524.71 404.58 646.97 512 646.97 619.42 646.97 565.71 524.71 512 402.46"
      />
      <polygon
        fill={fill}
        points="766.18 402.46 712.48 524.71 658.77 646.97 766.18 646.97 873.6 646.97 819.89 524.71 766.18 402.46"
      />
      <path
        fill={fill}
        d="M336.66,818.83l-20.8-82h11.19l9.73,41.48c2.43,10.22,4.62,20.43,6.09,28.34h.23c1.35-8.14,3.9-17.88,6.69-28.47l10.95-41.35h11.07l10,41.6c2.31,9.73,4.5,19.46,5.71,28.1h.25c1.7-9,4-18.12,6.57-28.35l10.82-41.35H416l-23.23,82H381.67l-10.35-42.69A249.18,249.18,0,0,1,366,749.38h-.24a258.71,258.71,0,0,1-6.32,26.76l-11.68,42.69Z"
      />
      <rect fill={fill} x="426.2" y="736.85" width="10.58" height="81.98" />
      <polygon
        fill={fill}
        points="470.84 745.85 445.9 745.85 445.9 736.85 506.6 736.85 506.6 745.85 481.55 745.85 481.55 818.83 470.84 818.83 470.84 745.85"
      />
      <polygon
        fill={fill}
        points="535.92 745.85 510.98 745.85 510.98 736.85 571.68 736.85 571.68 745.85 546.63 745.85 546.63 818.83 535.92 818.83 535.92 745.85"
      />
      <path
        fill={fill}
        d="M591.39,736.85v48.54c0,18.36,8.15,26.15,19.1,26.15,12.17,0,20-8,20-26.15V736.85h10.7v47.8c0,25.19-13.26,35.52-31,35.52-16.79,0-29.44-9.61-29.44-35V736.85Z"
      />
      <path
        fill={fill}
        d="M670.09,775.29h10.83c11.31,0,18.5-6.21,18.5-15.57,0-10.59-7.67-15.2-18.86-15.33a43.38,43.38,0,0,0-10.47,1ZM659.52,738a109.72,109.72,0,0,1,20.31-1.7c11.31,0,18.61,2.06,23.72,6.68,4.14,3.65,6.44,9.25,6.44,15.57,0,10.83-6.81,18-15.44,20.93v.36c6.32,2.19,10.09,8,12,16.54,2.68,11.44,4.62,19.35,6.32,22.5H702c-1.34-2.3-3.17-9.36-5.48-19.58-2.43-11.31-6.81-15.57-16.42-15.93h-10v35.51H659.52Z"
      />
    </svg>
  );
}
