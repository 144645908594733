import React from 'react';

export default function CompanyLogo({ children }) {
  return (
    <div
      className="flex bg-white items-center justify-center px-4 py-2 m-2 rounded-lg h-48 w-2/3 custom-box-shadow"
      style={{maxWidth: "230px"}}
    >
      {children}
    </div>
  );
}
