import React from 'react';

import StatsBox from '../StatsBox';
import Section from '../Section';
import Title from '../Title';
import SubTitle from '../SubTitle';
import GridList from '../GridList';

import data from '../../data/accomplishments-data';
import AnimationReveal from '../AnimationReveal';
import ContactUsButton from '../ContactUsButton';

export default function Accomplishments() {
  return (
    <AnimationReveal>
      <Section name="accomplishments">
        <div className="container mx-auto">
          <div className="text-center mb-12">
            <Title text="accomplishments.title" />
            <SubTitle text="accomplishments.subtitle" />
          </div>
          <div className="flex flex-col sm:flex-row mt-6 lg:px-24">
            <div className="w-full sm:w-1/3 text-center">
              <StatsBox primaryText="20+" secondaryText="accomplishments.experience" />
            </div>
            <div className="w-full sm:w-1/3 text-center">
              <StatsBox primaryText="50+" secondaryText="accomplishments.application" />
            </div>
            <div className="w-full sm:w-1/3 text-center">
              <StatsBox primaryText="300+" secondaryText="accomplishments.module" />
            </div>
          </div>
          <div className="container py-10 mx-auto">
            <GridList data={data} />
          </div>
          <div className="container mx-auto text-center">
            <ContactUsButton />
          </div>
        </div>
      </Section>
    </AnimationReveal>
  );
}
