import React from 'react';

import Layout from '../components/layout/Layout';
import Hero from '../components/sections/Hero';
import References from '../components/sections/References';
import Accomplishments from '../components/sections/Accomplishments';
import JobListing from '../components/sections/JobListing';
import Quality from '../components/sections/Quality';
import InformationSecurity from '../components/sections/InformationSecurity';
import AboutUs from '../components/sections/AboutUs';
import Mission from '../components/sections/Mission';

export default () => (
  <Layout>
    <Hero />
    <AboutUs />
    <Mission />
    <Quality />
    <InformationSecurity />
    <References />
    <Accomplishments />
    <JobListing />
  </Layout>
);
