import React from 'react';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import Button from '../Button';
import AnimationReveal from '../AnimationReveal';

export default function JobListing() {
  const intl = useIntl();

  return (
    <AnimationReveal>
      <section className="container mx-auto mt-32 mb-40 py-24 bg-primary text-gray-100 custom-box-shadow rounded-lg text-center md:px-24">
        <h3 className="text-5xl font-bold px-6">{intl.formatMessage({ id: 'workForUs' })}</h3>
        <p className="mt-8 text-xl font-light"></p>
        <p className="mt-8">
          <Link to="/career">
            <Button className="bg-gray-100 text-primary" size="xl">
              {intl.formatMessage({ id: 'moreInfo' })}
            </Button>
          </Link>
        </p>
      </section>
    </AnimationReveal>
  );
}
