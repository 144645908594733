import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Card from './Card';

const CustomerCard = ({ customer }) => (
  <Card className="bg-white h-full py-12 px-4 rounded-lg border-2 border-gray-300">
    <p className="text-xl font-semibold text-gray-900 pb-4 mb-4">
      <FormattedMessage id={customer.title} />
    </p>
    <div className="flex flex-col overflow-hidden">
      {customer.content.map(text => (
        <p key={text} className="text-gray-600 mb-2">
          <FormattedMessage id={text} />
        </p>
      ))}
    </div>
  </Card>
);

export default CustomerCard;
