export default [
  {
    title: 'references.productionSupport',
    content: [
      'references.qualityManagement',
      'references.accessControlSystem',
      'references.workingTime',
      'references.bom',
      'references.oee',
      'references.firstSample',
      'references.interventionControl',
      'references.measuringSystemControl',
      'references.operationControlDisplay',
      'references.productionPlanning',
    ]
  },
  {
    title: 'references.businessPortal',
    content: [
      'references.toolsAndMaintenance',
      'references.logisticProcess',
      'references.supplier',
      'references.freightRegistration',
      'references.patterning',
      'references.compliantHandling',
      'references.qualityCompliantHandling',
      'references.consignement',
    ]
  },
  {
    title: 'references.businessProcess',
    content: [
      'references.transport',
      'references.controlling',
      'references.priceData',
      'references.costSaving',
      'references.dispo',
      'references.transportTravelAgency',
      'references.storage',
      'references.inventory',
      'references.userTests',
      'references.labTest',
      'references.customs',
    ]
  }
];
