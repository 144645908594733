import React from 'react';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import iso9001Hun from '../../assets/Certop_9001_COhun.png';
import iso9001Eng from '../../assets/Certop_9001_COeng.png';

import iso27001Hun from '../../assets/Certop_27001_COhun.png';
import iso27001Eng from '../../assets/Certop_27001_COeng.png';

function Footer() {
  const intl = useIntl();
  const currentYear = new Date().getFullYear();
  const iso9001Src = intl.locale == 'hu' ? iso9001Hun : iso9001Eng;
  const iso27001Src = intl.locale == 'hu' ? iso27001Hun : iso27001Eng;

  return (
    <footer id="contact" className="adb-blue dark text-gray-300">
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-no-wrap flex-wrap flex-col footer-container">
        <div className="flex flex-wrap mb-4 w-full space-y-4 w-3/4 lg:w-3/4 md:w-full sm:w-full footer-flex">
          <div>
            <h2 className="text-lg font-semibold uppercase py-4">
              {intl.formatMessage({ id: 'companyInfo' })}
            </h2>
            <ul className="leading-loose text-sm lg:text-base">
              <li className="flex flex-row">
                <p className="font-semibold">{intl.formatMessage({ id: 'taxNumber' })}:&nbsp;</p>
                12787524-2-16
              </li>
              <li className="flex flex-row">
                <p className="font-semibold">{intl.formatMessage({ id: 'hq' })}:&nbsp;</p> 5143
                Jánoshida, Boldogházi u. 28.
              </li>
              <li className="flex flex-row">
                <p className="font-semibold">{intl.formatMessage({ id: 'site' })}:&nbsp;</p> 5100
                Jászberény Fazekas utca 20.
              </li>
              <li className="flex flex-row">
                <p className="font-semibold">{intl.formatMessage({ id: 'telephone' })}:&nbsp;</p>
                +36-30/336-49-13
              </li>
              <li className="flex flex-row">
                <p className="font-semibold">Email:&nbsp;</p> mail@adb-pro.hu
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-lg font-semibold uppercase py-4">
              {intl.formatMessage({ id: 'moreContent' })}
            </h2>
            <ul className="leading-loose text-sm lg:text-base">
              <li>
                <Link to="/career">{intl.formatMessage({ id: 'career' })}</Link>
              </li>
              <li>
                <Link to="/iso">
                  <img src={iso9001Src} className="isoLogo footer mt-3 p-2" />
                </Link>
                <Link to="/iso">
                  <img src={iso27001Src} className="isoLogo footer mt-3 p-2" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container mx-auto py-6 text-center">
        <div>&#169; {currentYear} ADB-Pro Informatikai Kft.</div>
      </div>
    </footer>
  );
}

export default Footer;
