import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import AnimationReveal from "../AnimationReveal";
import Section from "../Section";
import Title from "../Title";
import SubTitle from "../SubTitle";
import TargetIcon from "../../icons/TargetIcon";
import ContactUsButton from "../ContactUsButton";

export default function Mission() {
    const intl = useIntl();
  
    return (
      <AnimationReveal>
        <Section name="mission">
        <div className="container mx-auto text-center">
            <div className="container mx-auto text-center">
                <Title text="mission.title" />
                <SubTitle text="mission.subtitle" />
            </div>
            <div className="flex flex-col pt-10 w-full gap-y-10 lg:gap-y-6">
              {[...Array(5)].map((x, i) =>
                <div key={i} className="flex flex-row w-full items-center text-justify">
                  <TargetIcon className="w-10 h-10 flex-shrink-0 mr-4 text-primary"/>
                  <span className="text-lg grow">
                    <FormattedMessage 
                      id={`mission.mission${i+1}`} 
                      values={{
                          b: chunks => <b style={{fontWeight: "bold"}}>{chunks}</b>,
                      }}
                    />
                  </span>
                </div>
              )}
            </div>
            <div className="container mx-auto text-center">
                <ContactUsButton />
            </div>
        </div>
      </Section>
      </AnimationReveal>
    );
}
  