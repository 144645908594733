import React from 'react';

import CompanyLogo from '../CompanyLogo';
import Section from '../Section';

import SvgElectrolux from '../../svg/SvgElectrolux';
import SvgDometic from '../../svg/SvgDometic';
import SvgWittur from '../../svg/SvgWittur';
import SvgSei from '../../svg/SvgSei';
import Title from '../Title';
import AnimationReveal from '../AnimationReveal';
import SvgAccell from '../../svg/SvgAccell';
import SvgHaldex from '../../svg/SvgHaldex';
import SvgXylem from '../../svg/SvgXylem';

export default () => {
  return (
    <AnimationReveal>
      <Section>
        <Title className="mb-12" text="references.partners" />
        <div className="flex flex-col flex-wrap sm:flex-row md:-mx-3 items-center justify-center">
          <CompanyLogo>
            <SvgElectrolux fill="#257a8f" />
          </CompanyLogo>
          <CompanyLogo>
            <SvgDometic fill="#257a8f" />
          </CompanyLogo>
          <CompanyLogo>
            <SvgWittur fill="#257a8f" fill2="#257a8f" />
          </CompanyLogo>
          <CompanyLogo>
            <SvgSei fill="#257a8f" />
          </CompanyLogo>
          <CompanyLogo>
            <SvgAccell fill="#257a8f" />
          </CompanyLogo>
          <CompanyLogo>
            <SvgHaldex fill="#257a8f" />
          </CompanyLogo>
          <CompanyLogo>
            <SvgXylem fill="#257a8f" />
          </CompanyLogo>
        </div>
      </Section>
    </AnimationReveal>
  );
};
