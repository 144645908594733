import React from 'react';

export default function SvgHaldex({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox='0 0 744.09448 410.66928' id="svg2293">
        <defs
            id="defs2295" />
        <g
            transform="translate(-21.793162,-493.75497)"
            id="layer1">
            <g
            transform="translate(17.716533,17.675656)"
            id="g2311">
            <path
                d="M 728.7986,516.5437 C 728.7986,516.5437 618.14604,495.41095 376.1239,495.41095 C 134.171,495.41095 23.44914,516.5437 23.44914,516.5437 L 23.44914,846.21492 C 23.44914,846.21492 134.171,867.41696 376.1239,867.41696 C 618.14604,867.41696 728.7986,846.21492 728.7986,846.21492 L 728.7986,516.5437"
                style={{fillRule: 'nonzero', stroke: 'none'}}
                fill={fill}
                id="path28" />
            <path
                d="M 728.7986,516.5437 C 728.7986,516.5437 618.14604,495.41095 376.1239,495.41095 C 134.171,495.41095 23.44914,516.5437 23.44914,516.5437 L 23.44914,846.21492 C 23.44914,846.21492 134.171,867.41696 376.1239,867.41696 C 618.14604,867.41696 728.7986,846.21492 728.7986,846.21492 L 728.7986,516.5437 z "
                style={{fill: 'none', stroke: "#ffffff", strokeWidth: "3.31195545", strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: "4", strokeDasharray: 'none'}}
                id="path30" />
            <path
                d="M 346.46874,745.26265 C 346.46874,753.99288 339.40137,761.06025 330.67108,761.06025 C 321.94086,761.06025 314.87349,753.99288 314.87349,745.26265 L 314.87349,618.39675 C 314.87349,609.66646 321.94086,602.5991 330.67108,602.5991 C 339.40137,602.5991 346.46874,609.66646 346.46874,618.39675 L 346.46874,745.26265"
                style={{fill: '#ffffff', fillRule:'nonzero', stroke: "none", strokeWidth: "0.47799999", strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: "4", strokeDasharray: 'none'}}
                id="path32" />
            <path
                d="M 425.1796,643.40961 L 425.1796,618.18888 C 425.1796,609.45859 432.24697,602.39123 440.97725,602.39123 C 449.70748,602.39123 456.84414,609.45859 456.84414,618.18888 L 456.84414,742.83754 C 456.84414,751.56782 449.70748,758.63519 440.97725,758.63519 L 376.05461,758.63519 C 367.32432,758.63519 360.25696,751.56782 360.25696,742.83754 L 360.25696,659.27649 C 360.25696,650.54626 367.32432,643.40961 376.05461,643.40961 L 425.1796,643.40961 z M 425.1796,670.01609 L 396.84091,670.01609 C 393.6537,670.01609 391.09001,672.57979 391.09001,675.6977 L 391.09001,727.45568 C 391.09001,730.57365 393.6537,733.13729 396.84091,733.13729 L 419.49799,733.13729 C 422.61596,733.13729 425.1796,730.57365 425.1796,727.45568 L 425.1796,670.01609"
                style={{fill: '#ffffff', fillRule:'nonzero', stroke: "none", strokeWidth: "0.47799999", strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: "4", strokeDasharray: 'none'}}
                id="path34" />
            <path
                d="M 236.50902,715.33033 C 233.94538,715.33033 231.79742,717.47822 231.79742,720.04187 L 231.79742,730.01933 C 231.79742,732.65226 233.94538,734.73092 236.50902,734.73092 L 264.63985,734.80016 C 267.20349,734.80016 269.35144,732.72155 269.35144,730.15791 L 269.35144,675.28201 C 269.35144,672.64903 267.20349,670.57042 264.63985,670.57042 L 261.10619,670.57042 L 220.98853,670.57042 C 213.64406,670.57042 207.68529,664.61165 207.68529,657.26717 C 207.68529,649.99194 213.64406,644.03322 220.98853,644.03322 L 285.21833,644.03322 C 293.94856,644.03322 301.01592,651.10053 301.01592,659.83082 L 301.01592,743.39186 C 301.01592,752.12215 293.94856,759.18946 285.21833,759.18946 L 219.18709,759.18946 C 210.4568,759.18946 203.38944,752.12215 203.38944,743.39186 L 203.38944,705.49145 C 203.38944,696.76122 210.4568,689.62456 219.18709,689.62456 L 253.06881,689.69385 C 260.13612,689.69385 265.88702,695.37546 265.88702,702.51206 C 265.88702,709.64872 260.13612,715.39962 253.06881,715.39962 L 236.50902,715.33033"
                style={{fill: '#ffffff', fillRule:'nonzero', stroke: "none", strokeWidth: "0.47799999", strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: "4", strokeDasharray: 'none'}}
                id="path36" />
            <path
                d="M 534.93145,687.75377 C 537.56438,687.75377 539.64304,685.67516 539.64304,683.11152 L 539.64304,673.06477 C 539.64304,670.50113 537.56438,668.35323 534.93145,668.35323 L 506.80062,668.35323 C 504.23698,668.35323 502.15832,670.43184 502.15832,672.99548 L 502.15832,727.45568 C 502.15832,730.08862 504.23698,732.16722 506.80062,732.16722 L 510.40357,732.16722 L 550.52117,732.16722 C 557.79641,732.16722 563.75518,738.126 563.75518,745.47052 C 563.75518,752.74571 557.79641,758.70448 550.52117,758.70448 L 486.22214,758.70448 C 477.49191,758.70448 470.42454,751.63711 470.42454,742.90683 L 470.42454,659.34578 C 470.42454,650.61555 477.49191,643.54819 486.22214,643.54819 L 552.25338,643.54819 C 560.98366,643.54819 568.05097,650.61555 568.05097,659.34578 L 568.05097,697.66194 C 568.05097,706.39217 560.98366,713.45953 552.25338,713.45953 L 518.37165,713.45953 C 511.30429,713.45953 505.55344,707.70869 505.55344,700.64133 C 505.55344,693.50467 511.30429,687.75377 518.37165,687.75377 L 534.93145,687.75377"
                style={{fill: '#ffffff', fillRule:'nonzero', stroke: "none", strokeWidth: "0.47799999", strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: "4", strokeDasharray: 'none'}}
                id="path38" />
            <path
                d="M 173.87286,602.39123 C 165.14263,602.39123 158.07526,609.45859 158.07526,618.18888 L 158.07526,668.21465 L 114.56253,668.21465 L 114.56253,618.18888 C 114.56253,609.45859 107.49516,602.39123 98.76493,602.39123 C 90.03465,602.39123 82.96734,609.45859 82.96734,618.18888 L 82.96734,744.98549 C 82.96734,753.71572 90.03465,760.85237 98.76493,760.85237 C 107.49516,760.85237 114.56253,753.71572 114.56253,744.98549 L 114.56253,695.02901 L 158.07526,695.02901 L 158.07526,744.98549 C 158.07526,753.71572 165.14263,760.85237 173.87286,760.85237 C 182.60314,760.85237 189.67051,753.71572 189.67051,744.98549 L 189.67051,618.18888 C 189.67051,609.45859 182.60314,602.39123 173.87286,602.39123"
                style={{fill: '#ffffff', fillRule:'nonzero', stroke: "none", strokeWidth: "0.47799999", strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: "4", strokeDasharray: 'none'}}
                id="path40" />
            <path
                d="M 641.42674,700.29487 L 641.35745,700.43345 L 670.66616,664.61165 C 677.10997,656.50498 673.92271,648.88334 668.65684,644.37962 C 664.43028,640.70738 653.41352,643.06315 649.94915,647.15113 L 625.62915,676.73701 L 603.87278,647.84404 C 597.84472,639.73737 587.17441,639.94524 581.07711,644.1718 C 574.14833,648.95263 573.94045,658.72222 578.79063,665.58172 L 605.67428,702.65064 L 575.88054,740.13531 C 571.51539,746.09408 571.37681,754.68579 577.33558,759.05087 C 582.67074,762.93104 592.02458,763.34673 597.49832,756.41794 L 621.40258,726.55496 L 641.84248,755.03218 C 647.66267,763.20814 659.02584,763.34673 665.26171,759.18946 C 672.25979,754.6165 671.98268,744.77762 667.34038,737.84883 L 641.42674,700.29487"
                style={{fill: '#ffffff', fillRule:'nonzero', stroke: "none", strokeWidth: "0.47799999", strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: "4", strokeDasharray: 'none'}}
                id="path42" />
            </g>
        </g>
    </svg>

  );
}
