import React from 'react';
// eslint-disable-next-line react/prop-types
const SplitSection = ({ id, primarySlot, secondarySlot, reverseOrder }) => (
  <section id={id} className="lg:py-20 py-16">
    <div className="mx-auto items-center flex flex-col lg:flex-row lg:px-0 md:px-10 px-4">
      <div className="lg:w-3/4">{primarySlot}</div>
      <div
        className={`mt-10 lg:mt-0 w-full lg:w-1/3 ${reverseOrder && `order-last lg:order-first`}`}
      >
        {secondarySlot}
      </div>
    </div>
  </section>
);

export default SplitSection;
