import React from 'react';

import AnimationReveal from '../AnimationReveal';
import SubTitle from '../SubTitle';
import Section from '../Section';
import Title from '../Title';
import Card from '../Card';

import data from '../../data/quality-data';
import QualityIcon from '../../icons/QualityIcon';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import iso9001Hun from '../../assets/Certop_9001_COhun.png';
import iso9001Eng from '../../assets/Certop_9001_COeng.png';
import { Link } from 'gatsby';
import ContactUsButton from '../ContactUsButton';

export default function Quality() {
  const intl = useIntl();
  const iso9001Src = intl.locale == 'hu' ? iso9001Hun : iso9001Eng;
  return (
    <AnimationReveal>
      <Section name="quality">
        <div className="container mx-auto text-center">
          <div className="container mx-auto text-center">
            <Title text="quality.title" />
            <SubTitle text="quality.subtitle" />
          </div>
          <div className="flex flex-wrap py-16 w-full">
            <div className='px-3 pb-6 text-justify text-lg'>
            <FormattedMessage id="quality.paragraph" 
              values={{
                b: chunks => <b>{chunks}</b>,
              }}
            />
            </div>
            <div className='pb-10 w-full'>
               <div className='w-full lg:w-2/6 md:w-3/5 sm:w-2/3 mx-auto my-0'>
               <Link to="/iso">
                  <img src={iso9001Src} className="isoLogo mt-3 p-2" />
                </Link>
               </div>
            </div>
            {data.map((item, index) => (
              <div key={item} className={`p-3 ${index != data.length - 1 && index != data.length - 2 ? `w-full lg:w-1/2 md:w-full sm:w-full` : `w-full`}`} style={{zIndex: index}}>
                <Card className="bg-white h-full w-full py-5 px-6 lg:pr-10 rounded-lg border-2 border-gray-300">
                  <div className="text-gray-700 text-justify h-full flex flex-col gap-y-2 lg:flex-row items-center">
                    <QualityIcon className="w-8 h-8 flex-shrink-0 mr-4 text-primary" />
                    <div>
                      <FormattedMessage id={item} 
                        values={{
                          b: chunks => <b>{chunks}</b>,
                        }}
                      />
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </div>
          <div className="container mx-auto text-center">
            <ContactUsButton />
          </div>
        </div>
      </Section>
    </AnimationReveal>
  );
}
