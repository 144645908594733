import React from 'react';

export default function SvgAccell({ fill }) {
  return (
    <svg version="1.1" id="layer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 652 652"
     fill={fill}>
        <path id="path0" fill={fill} className="st0" d="M160.1,248.8c-7.4,0.5-8.8,0.7-13.3,1.8c-8,1.9-15.7,7-20.2,13.4c-0.6,0.8-1.2,1.6-1.2,1.7
            c-0.1,0.1-0.7,1.1-1.4,2.2c-2.1,3.5-3.8,7.1-9.1,19.2c-0.2,0.5-0.7,1.6-1.1,2.5c-0.4,0.9-0.7,1.7-0.7,1.7c0,0.1-0.3,0.8-0.7,1.7
            c-0.4,0.9-1,2.2-1.4,3c-3.8,8.4-4.7,16.1-2.3,20.1c2.3,4,6.8,5.6,16.9,6.2c11.4,0.7,23.3,0,27.2-1.7c0.3-0.1,1.3-0.5,2.2-0.8
            c6-2.1,10.9-5.9,15.1-11.5c1.8-2.4,6.9-12.5,6.9-13.7c0-0.4-20,0-20.2,0.4c-0.1,0.2-0.6,1.2-1.1,2.2c-3,6.8-6.5,8.5-16.1,8.4
            c-8.7-0.2-10.9-2.2-8.8-8.1c1.1-3.1,2-5.3,2.7-6.9c0.4-0.9,1.1-2.5,1.5-3.5c0.4-1,1.2-2.7,1.7-3.9s1-2.1,1-2.2s0.3-0.9,0.7-1.8
            c4.8-10.7,7.2-13,14.7-14c11.1-1.5,16.2,1.5,13,7.7c-0.2,0.4-0.4,1-0.6,1.3l-0.2,0.6h10.1h10.1l0.5-1.2c0.3-0.7,0.7-1.6,0.9-2.2
            c0.6-1.3,0.8-1.9,1.2-3.1c0.2-0.5,0.4-1.2,0.6-1.5c3.4-8.7,1.8-14.4-4.6-17C181.3,249.1,167.1,248.3,160.1,248.8 M228.7,248.8
            c-3.3,0.2-8.2,0.7-9.3,0.9c-0.6,0.1-1.9,0.4-2.8,0.6c-1.9,0.4-2.1,0.5-5.2,1.5c-7.8,2.8-14,8.1-18.9,16.1c-1.1,1.8-4.4,8.3-4.4,8.6
            c0,0.1-0.3,0.8-0.7,1.6c-0.4,0.8-0.9,2.1-1.3,2.9s-0.8,1.9-1.1,2.5c-0.3,0.6-0.9,2-1.4,3c-0.5,1-0.9,2-0.9,2.1
            c0,0.1-0.3,0.8-0.7,1.6c-1.3,2.7-2.1,4.7-2.1,4.8c0,0.1-0.3,0.7-0.6,1.5c-0.3,0.7-0.7,1.6-0.8,1.9c-0.4,1-1.6,4.7-1.9,6.3
            c-0.2,0.8-0.5,2-0.6,2.6c-1.7,6.6,1.8,12.1,8.9,13.9c6.7,1.7,25.1,1.9,32.8,0.4c2.8-0.6,3.1-0.6,3.6-0.9c0.3-0.1,1.3-0.5,2.2-0.8
            c9.1-3.2,15.3-9.7,20.3-21.1c1.1-2.5,1.8-4,1.8-4.1c0-0.2-19.6-0.1-20,0.1c-0.2,0.1-0.4,0.3-0.4,0.6c0,0.5-2.2,4.8-3,5.7
            c-3,3.8-5.7,4.8-13.3,4.8c-12.1,0-12.8-2.6-5-19c0.5-1,0.8-1.8,0.8-1.9c0-0.1,0.6-1.4,2.3-5.2c0.4-1,0.8-1.8,0.8-1.9
            c0-1.3,4.1-7.5,6-9.2c3-2.7,9.2-4,15.4-3.3c6.4,0.7,7.8,2.7,5.5,8.1c-0.3,0.7-0.5,1.3-0.5,1.5c0,0.2,4,0.2,10,0.2l10-0.1l0.4-0.7
            c0.5-1,1.1-2.4,1.8-4.3c0.3-0.9,0.7-1.9,0.8-2.2c3.1-7.6,2.2-14.4-2.3-16.6C250.9,249.2,239,248.1,228.7,248.8 M79.3,250.2
            c-0.5,0.6-3.6,4.7-6.9,9.2c-3.3,4.5-6.3,8.5-6.6,8.8c-0.3,0.4-1.4,1.8-2.4,3.2c-1,1.4-2.1,2.9-2.5,3.4c-0.4,0.5-2,2.6-3.6,4.8
            c-4,5.3-15.6,21-18.2,24.4c-2.7,3.6-2.5,3.4-10.3,13.9c-1.8,2.4-2,2.7-2.5,3.2c-0.9,1-0.5,1,9.6,1c9.8,0,10,0,10.5-0.6
            c0.3-0.3,0.6-0.7,0.8-0.9c0.2-0.2,0.6-0.8,1-1.3c0.7-1,3.8-5.3,5.7-7.9c0.6-0.8,1.3-1.6,1.4-1.8c0.2-0.1,6.3-0.2,13.6-0.1l13.3,0.1
            l0,1.1c0,0.6-0.2,2.1-0.3,3.4c-0.2,1.2-0.4,3.4-0.6,4.7c-0.2,1.4-0.3,2.6-0.4,2.8c-0.1,0.3,2.3,0.4,9.9,0.4l10,0.1l0.3-1.8
            c0.2-1,0.3-2.2,0.3-2.7s0.1-1.7,0.3-2.6c0.1-0.9,0.4-2.8,0.6-4.1c0.2-1.3,0.5-3.8,0.7-5.6c0.2-1.8,0.5-4.3,0.7-5.6
            c0.3-2.3,0.5-4.1,1.1-8.6c0.3-2.4,1.3-10.1,1.7-13.5c0.3-2.4,0.7-6,1.1-8.8c0.1-1.2,0.5-4.2,0.8-6.7c0.9-7.3,1.1-9,1.4-10.7
            c0.2-0.9,0.2-1.7,0.1-1.9c-0.1-0.2-5.7-0.3-15-0.3H80.2L79.3,250.2 M270.8,251.3c-0.5,1.2-1.1,2.4-1.2,2.7c-0.1,0.3-0.5,1.2-0.9,2
            c-0.3,0.8-0.9,2.1-1.3,2.9c-0.4,0.8-0.7,1.5-0.7,1.6c0,0.1-0.4,0.9-0.8,1.9c-1.7,3.8-2.3,5-2.3,5.2c0,0.1-0.4,1.1-1,2.2
            c-0.5,1.1-1,2.1-1,2.2c0,0.1-0.3,0.8-0.7,1.6c-1.4,3-1.9,4.3-2.5,5.5c-0.9,2-1.3,3.1-2.2,5c-0.4,1-0.8,1.8-0.8,1.9
            c0,0.1-0.5,1.3-2,4.6c-0.4,1-0.8,1.8-0.8,1.9s-0.4,0.9-0.8,2c-0.5,1-0.8,1.9-0.8,2c0,0.1-0.4,1-1,2.2c-1.3,2.8-2.1,4.7-2.1,4.8
            c0,0.1-0.4,0.9-0.8,1.9c-1.7,3.6-2.3,5-2.3,5.1c0,0.1-0.3,0.8-0.7,1.6c-0.7,1.6-1.6,3.7-2.3,5.3c-0.2,0.5-0.8,1.7-1.2,2.7
            c-0.4,0.9-0.7,1.8-0.6,1.9c0.1,0.1,12.3,0.2,27.1,0.1l26.9-0.1l0.5-1c0.6-1.3,2.5-5.3,2.5-5.5c0-0.1,0.3-0.8,0.7-1.6
            c0.4-0.8,0.9-2.1,1.3-2.9c0.3-0.8,0.8-1.9,1.1-2.5c1.1-2.3,2.9-2.1-16.3-2.1c-19.4,0-17.7,0.3-16.5-2.4c1.6-3.7,3.3-7.4,4.4-9.9
            c0.4-0.8,0.7-1.6,0.7-1.8s0-0.3,0.1-0.3s7.3-0.1,16.2-0.2l16.1-0.1l1.1-2.5c0.6-1.4,1.3-2.9,1.5-3.4c0.2-0.5,0.7-1.5,1-2.2
            c0.3-0.8,0.9-2.1,1.3-2.9c0.4-0.8,0.7-1.6,0.7-1.8c0-0.2-5.7-0.3-15.6-0.3c-17.8,0-16.4,0.2-15-2.6c0.5-1,0.9-1.9,0.9-2
            c0-0.1,0.3-0.8,0.7-1.6c1.4-3.2,1.9-4.2,2.3-5.4c0.8-2.1-0.6-1.9,18-1.9l16.9,0l0.7-1.6c0.4-0.9,1-2.2,1.4-3.1
            c0.4-0.9,0.7-1.6,0.7-1.7c0-0.1,0.4-1,0.9-2.2c0.5-1.1,1.3-2.9,1.7-4c0.4-1.1,0.9-2.1,1-2.2c1.1-1.3,2.3-1.3-26.4-1.3h-26.7
            L270.8,251.3 M334.7,249.5c-0.1,0.2-0.6,1-0.9,1.9c-0.4,0.8-0.9,2.1-1.2,2.8c-0.8,1.8-1.2,2.7-1.9,4.4c-0.3,0.8-0.9,1.9-1.1,2.6
            c-0.3,0.7-0.8,1.8-1.1,2.5c-1,2.2-1.3,2.9-2.1,4.6c-0.4,0.9-0.7,1.7-0.7,1.7c0,0.1-0.4,0.9-0.8,1.9c-0.4,1-1.1,2.5-1.5,3.5
            c-0.4,0.9-1.2,2.7-1.7,3.9c-0.6,1.2-1,2.2-1,2.3c0,0.1-0.3,0.8-0.7,1.6c-1.3,3-1.9,4.3-2.4,5.4c-0.3,0.6-0.7,1.7-1.1,2.4
            c-0.3,0.7-0.9,2.1-1.3,3.1c-0.4,1-1,2.5-1.4,3.2c-0.4,0.8-0.8,1.8-1.1,2.4c-0.4,0.9-1.1,2.4-2.2,5c-0.3,0.7-0.5,1.2-0.5,1.3
            c0,0.1-0.4,1-0.8,2c-0.5,1-1.2,2.7-1.7,3.7c-0.5,1-0.8,1.9-0.8,2s-0.3,0.8-0.7,1.7s-0.9,2-1.1,2.5c-0.2,0.5-0.7,1.6-1.1,2.5
            c-0.4,0.9-0.7,1.7-0.7,1.8s11.4,0.1,25.2,0.1c27.4,0,25.8,0.1,26.3-1.5c0.1-0.3,0.6-1.6,1.1-2.7c0.5-1.1,0.9-2.1,0.9-2.2
            c0-0.1,0.3-0.8,0.7-1.6c0.4-0.8,1.2-2.7,1.8-4.1s1.3-3,1.6-3.5c0.6-1.3,0.9-1.3-15.2-1.3c-16.3,0-16.2,0-15-1.3
            c0.1-0.1,0.4-0.8,0.7-1.4c0.5-1.3,1.2-2.8,2.5-5.6c0.4-1,0.8-1.8,0.8-1.9c0-0.1,0.6-1.4,2.3-5.2c0.4-1,0.8-1.8,0.8-1.9
            c0-0.1,0.4-0.9,0.8-1.9c0.4-0.9,1.2-2.5,1.6-3.5c0.4-1,1.1-2.5,1.5-3.4c0.4-0.9,1-2.1,1.3-2.8c0.8-1.9,1.2-2.8,1.9-4.3
            c0.3-0.7,0.6-1.4,0.6-1.5c0-0.1,0.4-1,0.8-2c0.5-1,1.2-2.7,1.7-3.7c0.5-1,0.8-1.9,0.8-2c0-0.1,0.3-0.8,0.7-1.6
            c0.4-0.8,0.9-2.1,1.3-2.9c0.3-0.8,0.8-1.8,1-2.4c0.2-0.5,0.6-1.4,0.8-1.8c0.2-0.5,0.8-1.7,1.2-2.8c0.5-1.1,0.9-2,0.9-2.2
            C354.3,249,335.1,249.1,334.7,249.5 M389.2,249.5c-0.1,0.2-0.6,1-0.9,1.9c-0.6,1.5-1,2.3-2.7,6c-0.4,0.9-0.7,1.6-0.7,1.7
            c0,0.1-0.6,1.5-2.3,5.1c-0.4,1-0.8,1.8-0.8,1.9s-0.4,0.9-0.8,2c-0.5,1-0.8,1.9-0.8,2c0,0.1-0.4,0.9-0.8,1.9c-0.4,1-1.2,2.7-1.7,3.9
            c-0.5,1.2-1.3,2.9-1.7,3.9c-0.4,1-0.8,1.8-0.8,1.9s-0.4,0.9-0.8,1.9c-1.6,3.5-2.3,5-2.3,5.1c0,0.1-0.4,0.9-0.8,1.9
            c-1.5,3.2-2,4.4-2,4.6c0,0.1-0.4,0.9-0.8,1.9c-1.7,3.6-2.3,5-2.3,5.1c0,0.1-0.4,0.9-0.8,1.9c-0.5,1-0.8,1.8-0.8,2
            c0,0.1-0.4,1.1-1,2.3c-0.5,1.1-1.1,2.5-1.4,3c-0.2,0.5-0.9,2.1-1.5,3.4c-0.6,1.3-1.3,3-1.6,3.6c-0.9,2-1.6,3.7-1.6,3.8
            c0,0.2,50.4,0.1,50.7-0.1c0.3-0.2,1.5-2.7,1.5-3c0-0.1,0.4-0.9,0.8-1.9c0.7-1.5,1.4-3.1,3.2-7.4c0.2-0.5,0.7-1.5,1.1-2.4
            c0.4-0.8,0.6-1.6,0.6-1.8c-0.1-0.1-7-0.2-15.4-0.2c-13.3,0-15.3-0.1-15.3-0.4c0-0.4,0.3-1.1,2-4.8c0.4-0.8,0.8-1.8,1-2.2
            c0.2-0.5,0.8-1.9,1.4-3.2c0.6-1.3,1.4-3.2,1.8-4.2c0.4-1,0.8-1.8,0.8-1.9c0-0.1,0.3-0.8,0.7-1.6c0.4-0.8,1.1-2.6,1.7-3.9
            c0.6-1.3,1.4-3.2,1.9-4.2c0.4-1,0.8-1.8,0.8-1.9s0.3-0.8,0.7-1.6c1-2.1,2.5-5.6,3.6-8.1c0.5-1.2,1.2-2.7,1.5-3.5
            c0.3-0.8,1.5-3.4,2.5-5.8c1-2.4,2.2-5.1,2.7-6.1c1.4-3.1,2.3-2.8-8.6-2.8C391.6,249.2,389.4,249.2,389.2,249.5 M89.1,264.1
            c0,0.3-0.5,4.4-1.1,8.6c-0.1,1-0.3,2.3-0.4,2.9c-0.1,0.6-0.3,1.8-0.4,2.7c-0.1,0.8-0.6,3.9-1,6.7c-0.4,2.9-0.8,5.8-1,6.5
            c-0.2,0.7-0.3,1.8-0.3,2.4s-0.1,1.1-0.2,1.2c-0.5,0.3-18.4,0.2-18.5-0.1c-0.1-0.2,2.5-4.1,3-4.5c0.1-0.1,1.2-1.7,2.5-3.5
            c1.3-1.9,2.4-3.4,2.5-3.5c0.1-0.1,0.6-0.8,1.2-1.7c0.6-0.8,1.8-2.6,2.7-3.8c5.6-7.6,7.7-10.6,9-12.4
            C88.6,263.8,89.1,263.4,89.1,264.1 M305.5,329.8c-5.2,0.6-6.8,0.9-11.2,2.3c-7.7,2.4-13.8,7.1-19.2,14.8c-1.2,1.7-3.9,6.8-5.3,10
            c-0.4,1-1.2,2.6-1.6,3.6c-0.4,1-0.8,1.8-0.8,1.9s-0.4,0.9-0.8,1.9c-0.4,1-1.2,2.7-1.7,3.9c-0.5,1.2-1.2,2.8-1.6,3.6
            c-1.1,2.4-1.7,3.8-3.2,7.4c-4.4,10.7-3.2,18.2,3.5,21.2c5.1,2.2,8.7,2.7,21.9,2.7c11.2,0,13.5-0.2,20.2-1.7c1.6-0.4,5.8-2,6.6-2.6
            c0.4-0.3,0.8-0.5,0.9-0.5c0.4,0,3.1-2.1,4.9-3.8c3.5-3.3,6.6-7.9,10-14.9c1.2-2.4,1.7-3.5,3-6.5c0.8-1.9,1.3-3.1,1.8-4.1
            c1.6-3.7,3-6.8,3-7c0-0.5-33.4-0.1-33.7,0.3c-0.1,0.2-0.6,1.2-1.1,2.2c-0.4,1-1.1,2.4-1.4,3.1c-0.3,0.7-0.6,1.4-0.6,1.4
            c0,0.1-0.5,1.2-1.1,2.5c-0.6,1.3-1.2,2.6-1.3,2.8c-0.2,0.5,0.3,0.5,6.5,0.5c7.8,0,7.4-0.3,5.4,3.3c-3.6,6.7-6.4,8.5-13.9,9.3
            c-6.4,0.6-12.4-0.5-13.7-2.6c-1.1-1.8-0.7-7.2,0.6-8.7c0.1-0.1,0.2-0.4,0.2-0.6c0-0.3,0.6-1.8,3-7c0.4-0.8,0.7-1.5,0.7-1.6
            c0-0.1,0.4-0.9,0.8-1.9c0.4-1,1.1-2.4,1.4-3.2c6.4-14.3,10-17,23.1-17c7.4,0,9.9,1.4,9.6,5.3l-0.1,2.1l9.7-0.1
            c5.3,0,9.8-0.1,9.9-0.1c0.2,0,0.9-1.6,1.8-4.3c2.7-8.2,1.8-13.7-2.7-15.9c-2.2-1.1-2.6-1.2-5.9-1.8
            C330.2,329.5,310.3,329.3,305.5,329.8 M450.1,329.6c-0.5,0.1-2.1,0.2-3.6,0.4c-15.8,1.7-27.7,10.3-33.9,24.7c-0.4,1-1.2,2.6-1.6,3.6
            c-0.4,1-0.8,1.8-0.8,1.9c0,0.1-0.4,0.9-0.8,1.9c-0.4,1-1.3,2.8-1.8,4.2c-0.6,1.3-1.2,2.8-1.5,3.4c-0.2,0.5-0.5,1.2-0.7,1.5
            c-0.1,0.3-0.7,1.5-1.2,2.8c-0.5,1.2-1,2.3-1,2.3c0,0.1-0.2,0.7-0.6,1.3c-2.5,5.4-3.6,12-2.7,15.5c2,7.5,8.4,10,25.9,10.1
            c25.6,0.1,38.4-7.6,46.8-27.9c0.3-0.7,0.9-2.1,1.3-3c0.4-1,0.8-1.8,0.8-1.9c0-0.1,0.4-0.9,0.8-1.9c0.4-1,1.1-2.5,1.5-3.5
            c0.4-0.9,1.2-2.7,1.7-3.9c0.6-1.2,1-2.2,1-2.3c0-0.1,0.3-0.8,0.7-1.6c1.5-3.4,1.8-4.1,2.5-6.4c1.2-4.1,1.3-4.6,1.3-7.8
            c0-4.4-0.7-6.2-3.5-8.7c-1.3-1.2-4.4-2.9-5.3-2.9c-0.3,0-0.6-0.1-0.6-0.2c-0.1-0.1-0.6-0.3-1.2-0.4c-0.6-0.1-2.1-0.4-3.3-0.6
            C468.2,329.7,452.4,329.3,450.1,329.6 M355.1,331.3c-0.3,0.7-0.9,2-1.2,2.8c-0.4,0.8-0.7,1.5-0.7,1.6c0,0.1-0.4,0.9-0.8,1.9
            c-1.6,3.5-2.3,5-2.3,5.1c0,0.1-0.4,1.1-1,2.2c-0.5,1.2-1,2.2-1,2.2c0,0.1-0.4,0.9-0.8,1.9c-1.6,3.5-2.3,5-2.3,5.1
            c0,0.1-0.4,0.9-0.8,1.9c-0.4,1-1.3,2.8-1.8,4.2c-0.6,1.3-1.2,2.8-1.4,3.2c-0.2,0.5-0.6,1.4-0.9,2.1c-0.3,0.7-1.1,2.6-1.8,4.2
            c-1.5,3.5-2.6,6.1-3,6.9c-0.3,0.7-1.5,3.3-2.9,6.7c-0.7,1.5-1.5,3.5-1.9,4.4c-0.4,0.8-1.2,2.6-1.7,3.9c-0.6,1.3-1.4,3.1-1.7,3.9
            c-0.4,0.8-0.7,1.5-0.7,1.6c0,0.1-0.4,1-0.9,2.2c-0.5,1.1-1,2.3-1.1,2.7l-0.2,0.6l9.7-0.1c8.3-0.1,9.8-0.1,10-0.5
            c0.1-0.2,0.8-1.6,1.4-3.1c0.6-1.5,1.5-3.3,1.8-4.1c0.4-0.8,0.7-1.5,0.7-1.6c0-0.1,0.4-0.9,0.8-1.9c1.8-3.8,2.4-5.2,2.8-6.3
            c0.8-1.9,0.2-1.8,9.4-1.8c9.4,0,10.2,0.1,11.6,1.6c1.7,1.8,1.3,4.4-1.4,10.3c-1.1,2.3-1.8,4-2.3,5.3c-0.3,0.6-0.6,1.3-0.7,1.4
            c-0.1,0.1-0.2,0.4-0.2,0.5c0,0.1,4.4,0.2,9.8,0.2h9.8l0.6-1.3c0.3-0.7,0.6-1.3,0.6-1.4c0-0.1,0.4-0.9,0.8-1.9c0.4-1,1.3-2.8,1.9-4.2
            c0.6-1.3,1.3-2.9,1.5-3.5c2-4.6,2.3-5.6,2.3-8.1c0-4.1-3-6.9-7.6-6.9c-1.4,0-1.8-0.8-0.6-1.3c0.6-0.2,1.2-0.4,1.4-0.4
            c0.8,0,6.4-2,7.7-2.7c5.3-3,8.3-6.3,11.6-13.3c1.7-3.5,2.5-5.2,2.5-5.4c0-0.1,0.3-0.8,0.6-1.5c1-2.3,1.1-2.4,1.6-4.2
            c2.4-7.4,1.3-12-3.2-14.3c-4.1-2-7.2-2.2-32.2-2.3l-21,0L355.1,331.3 M497.7,331.3c-0.3,0.7-1,2.1-1.4,3.1c-0.4,1-0.8,1.8-0.8,1.9
            c0,0.1-0.4,1.1-1,2.3c-0.5,1.2-1,2.2-1,2.2c0,0.1-0.4,1.1-1,2.3c-0.5,1.2-1.1,2.4-1.2,2.8c-0.1,0.3-0.5,1.2-0.9,2
            c-0.3,0.8-0.8,1.8-1,2.2c-0.2,0.5-0.7,1.6-1.1,2.5c-0.4,0.9-0.7,1.7-0.7,1.8c0,0.1-0.4,0.9-0.8,1.9c-1.5,3.2-2.3,5-2.3,5.1
            c0,0.1-0.3,0.8-0.7,1.6c-0.4,0.8-0.9,2.1-1.3,2.9c-1,2.3-1.3,3-2.1,4.7c-0.4,0.9-0.7,1.7-0.7,1.7s-0.4,0.9-0.8,1.9
            c-7.6,16.5-8.1,22-2.6,25.5c7.4,4.7,32.8,4.9,44.4,0.5c7.4-2.9,12.9-7.8,17.4-15.6c0.7-1.2,3-5.9,3-6.1c0-0.1,0.4-1.1,1-2.2
            c0.5-1.2,1-2.2,1-2.3s0.4-0.9,0.8-1.9c1.5-3.2,1.9-4.3,2.7-6c0.4-1,1-2.4,1.4-3.3c0.4-0.9,1-2.2,1.4-3.1s0.7-1.6,0.7-1.7
            s0.3-0.8,0.7-1.6c0.8-1.7,2.5-5.7,3.3-7.4c1-2.4,2.6-6,3.4-7.7c0.4-1,0.8-1.8,0.8-1.9c0-0.1,0.2-0.6,2.1-4.8
            c0.4-0.9,0.9-2.1,1.2-2.7c0.2-0.6,0.6-1.3,0.7-1.5c0.2-0.3-1.7-0.4-9.6-0.4c-7.5,0-9.9,0.1-10.1,0.4c-0.1,0.2-0.6,1-0.9,1.9
            c-0.7,1.8-1.4,3.3-2.6,5.8c-0.4,1-0.8,1.8-0.8,1.9c0,0.1-0.3,0.8-0.7,1.6c-0.4,0.8-0.9,2.1-1.3,2.9c-1,2.3-1.3,3-2.1,4.7
            c-0.4,0.9-0.7,1.7-0.7,1.7c0,0.1-0.4,0.9-0.8,2c-0.5,1-1.2,2.7-1.7,3.7c-0.5,1-0.8,1.9-0.8,2c0,0.1-0.4,0.9-0.8,2
            c-0.5,1-0.8,1.9-0.8,2s-0.4,0.9-0.8,1.9c-1.7,3.6-2.3,5-2.3,5.1c0,0.1-0.6,1.4-2,4.5c-0.3,0.6-0.9,1.9-1.3,2.9
            c-3.4,7.9-5.8,9.5-14.7,9.7c-12,0.4-13.8-1.3-10.2-9.6c1.4-3.3,2.6-5.9,2.9-6.7c0.3-0.7,1.5-3.3,2.9-6.7c0.7-1.5,1.5-3.5,1.9-4.3
            c0.4-0.9,1.1-2.5,1.6-3.6c0.5-1.2,1.3-2.9,1.7-3.9s0.8-1.8,0.8-1.9s0.3-0.9,0.7-1.7c0.4-0.9,0.9-2,1.1-2.5c0.2-0.5,0.7-1.6,1.1-2.5
            c0.7-1.6,1.2-2.8,2-4.5c2.4-5.6,3.5-8,3.7-8.3c0.1-0.1-4.3-0.2-9.7-0.2h-9.8L497.7,331.3 M570.4,330.7c-0.2,0.4-0.6,1.4-1,2.3
            c-0.4,0.8-0.7,1.6-0.7,1.6c0,0.1-0.4,0.9-0.8,1.9c-0.7,1.5-1.5,3.3-3.2,7.4c-0.2,0.5-0.7,1.6-1.1,2.5c-0.4,0.9-0.7,1.7-0.7,1.7
            c0,0.1-0.6,1.5-2.3,5.1c-0.4,1-0.8,1.8-0.8,1.9c0,0.1-0.3,0.8-0.7,1.6c-0.4,0.8-1.1,2.6-1.7,3.9c-0.6,1.3-1.3,3-1.7,3.8
            s-1,2.4-1.5,3.5c-0.5,1.2-1.4,3.1-1.9,4.4c-0.5,1.2-1.2,2.7-1.5,3.4c-1,2.4-2.6,6-3.4,7.7c-0.4,1-0.8,1.8-0.8,1.9
            c0,0.1-0.7,1.6-2.3,5.1c-0.4,1-0.8,1.8-0.8,1.9c0,0.1-0.4,0.9-0.8,2c-0.5,1-0.8,1.9-0.8,2c0,0.1-0.4,0.9-0.8,1.9
            c-1.1,2.4-2,4.5-2,4.6c0,0.1,4.4,0.1,9.8,0.1h9.8l0.7-1.6c0.4-0.9,0.9-2.1,1.2-2.6s0.7-1.6,1-2.3c1.3-3,1.7-3.9,1.7-4
            c0-0.1,0.8-1.9,2.7-6l0.9-2l8.2,0c28.2,0,34.9-3.3,44.1-21.5c1.9-3.8,2.4-4.9,3.4-7.3c0.2-0.6,0.6-1.4,0.7-1.8
            c0.1-0.3,0.4-0.9,0.5-1.3c2.7-6.4,3.2-13.5,1.1-16.9c-1-1.7-4.2-4.1-5.5-4.1c-0.2,0-0.6-0.1-0.9-0.2c-1.8-0.9-3-1-25.4-1.1
            l-22.4-0.1L570.4,330.7 M457.5,346.4c5.3,1.1,5.7,4,1.8,12.8c-0.3,0.7-0.8,1.9-1.2,2.7c-1,2.4-1.3,3-2.1,4.7
            c-0.4,0.9-0.7,1.7-0.7,1.7c0,0.1-0.4,0.9-0.8,1.9c-0.4,1-1.1,2.4-1.4,3.2c-4.9,11.2-7.9,13.2-19.6,13.2c-9.2,0-11.2-0.9-11.2-4.8
            c0-2,1.3-6.5,2.5-8.7c0.3-0.6,0.6-1.1,0.6-1.3s0.4-1.2,1-2.3c1.3-2.8,2.1-4.7,2.1-4.8c0-0.1,0.8-1.9,2.9-6.5
            c3.6-7.7,6.4-10.5,11.8-11.7C446.1,345.8,454.7,345.8,457.5,346.4 M388.2,347.2c2.1,1.1,2.3,3,0.8,6.5c-5.5,13-5.2,12.8-19.7,12.8
            c-10.9,0-10.1,0.2-8.8-2.6c0.4-1,1.2-2.7,1.7-3.9c0.5-1.2,1.2-2.8,1.6-3.6c0.8-1.8,1.3-3,1.9-4.3c1.2-2.8,2.3-5.1,2.6-5.3
            C368.8,346.2,387.2,346.6,388.2,347.2 M602,347.3c2,1.1,2.2,3.3,0.7,6.7c-0.2,0.6-0.5,1.3-0.7,1.6c-1.8,4.7-4.7,9.2-6.5,10.2
            c-2.3,1.2-2.6,1.3-12,1.4c-10.4,0.2-9.7,0.4-8.5-2.4c0.8-1.7,1.1-2.4,2-4.6c0.5-1.2,1.1-2.5,2.5-5.5c0.4-0.8,0.7-1.5,0.7-1.6
            c0-0.1,0.4-1.1,1-2.3c0.5-1.2,1-2.2,1-2.3c0-0.1,0.3-0.6,0.6-1.1l0.6-0.9l8.8,0.1C600.7,346.7,600.8,346.7,602,347.3"/>
    </svg>
  );
}
