import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// eslint-disable-next-line react/prop-types
const ContactUsButton = ({ className, children }) => (
    <AnchorLink
        href="#contact"
        offset={() => -10}
    >
        <button
            className={`py-3 px-16 text-xl my-10 bg-primary text-white rounded bold ${className}`}
        >
            <FormattedMessage id="menu.contact" />
        </button>
  </AnchorLink>
);

export default ContactUsButton;
