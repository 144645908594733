import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import AnimationReveal from "../AnimationReveal";
import Section from "../Section";
import Title from "../Title";
import SubTitle from "../SubTitle";
import SplitSection from '../SplitSection';
import SvgBusinessDeal from '../../svg/unDraw/SvgBusinessDeal';
import SvgOurSolution from '../../svg/unDraw/SvgOurSolution';
import SvgData from '../../svg/unDraw/SvgData';
import SvgInTheOffice from '../../svg/unDraw/SvgInTheOffice';
import SvgPredictiveAnalytics from '../../svg/unDraw/SvgPredictiveAnalytics';
import '../../css/tailwind.css'
import ContactUsButton from "../ContactUsButton";

export default function AboutUs() {
    const intl = useIntl();
    let svgOrder = [ <SvgBusinessDeal />, <SvgOurSolution />, <SvgData />, <SvgInTheOffice />, <SvgPredictiveAnalytics /> ];
  
    return (
      <AnimationReveal>
        <Section name="aboutUs">
        <div className="container mx-auto text-center">
            <div className="container mx-auto text-center">
                <Title text="aboutUs.title" />
            </div>
            {[...Array(5)].map((x, i) =>
                <SplitSection
                    key={i}
                    reverseOrder={i % 2 == 1}
                    primarySlot={
                        <div className={`${i % 2 == 0 ? 'lg:pr-32 xl:pr-48' : 'lg:pl-32 xl:pl-48'}`}>
                            <p className="lg:text-xl font-light leading-relaxed text-justify lg:text-justify text-gray-800">
                                <FormattedMessage 
                                    id={`aboutUs.aboutUs${i+1}`} 
                                    values={{
                                        b: chunks => <b style={{fontWeight: "bold"}}>{chunks}</b>,
                                    }}
                                />
                            </p>
                        </div>
                    }
                    secondarySlot={svgOrder[i]}
                />
            )}
            <div className="container mx-auto text-center">
                <SubTitle text="aboutUs.footer" />
                <ContactUsButton />
            </div>
        </div>
      </Section>
      </AnimationReveal>
    );
}
