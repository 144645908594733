import React from 'react';
// eslint-disable-next-line react/prop-types
const Card = ({ className, children }) => (
  <div
    className={`rounded-lg border border-solid border-gray-200 ${className} custom-box-shadow`}
  >
    {children}
  </div>
);

export default Card;
