import React from 'react';

export default function SvgDometic({ fill }) {
  return (
    <svg version="1.1" id="layer" x="0px" y="0px" viewBox="0 0 652 652">
      <path
        fill={fill}
        d="M199,286.5h-23.9c-2.4,0-4.4,1.9-4.5,4.4v67.3H199c19.8,0,35.9-16.1,35.9-35.9c0-19.8-16.1-35.9-35.9-35.9V286.5z
	 M188.5,340.1v-35.8H199c9.8,0,17.3,8,17.3,17.9c0,9.9-7.5,17.9-17.3,17.9H188.5z M379.8,286.5c-1.8,0-3.5,1-4.3,2.7l-15.2,40.3
	l-15.2-40.3c-0.7-1.7-2.4-2.7-4.2-2.7h-20.9V358H338v-41.8l16.4,41.3h12.1l16.4-41.2v41.8h17.9v-71.6H379.8z M532.8,358h19.4v-71.5
	h-19.4V358z M410.3,286.5v67.1c0,2.5,2,4.5,4.5,4.5H461v-18h-32.7v-11.8h26.9v-15.2h-26.9v-8.9H461v-17.7H410.3z M467.9,286.5v13.4
	c0,2.5,2,4.5,4.5,4.5h14.9V358h19.4v-53.6h19.4v-18.1L467.9,286.5z M277.1,285.6c-20,0-36.2,16.4-36.2,36.6
	c0,20.3,16.1,36.7,36.1,36.7c20,0,36.2-16.4,36.2-36.7C313.2,301.8,296.9,285.6,277.1,285.6z M277.1,340.6c-10,0-17.9-8.2-17.9-18.4
	c0-10.3,8-18.4,17.9-18.4s17.9,8.2,17.9,18.4C295,332.5,286.8,340.6,277.1,340.6z M594.6,303.8c5.3,0,10.3,2.3,13.6,6.4l12.9-12.9
	c-6.7-7.5-16.3-11.8-26.4-11.8c-20,0-36.4,16.5-36.4,36.8c0,20.3,16.4,36.7,36.4,36.7c10.1-0.1,19.6-4.4,26.4-11.8l-12.9-12.8
	c-3.4,4.1-8.4,6.4-13.6,6.4c-9.7,0-17.8-8.2-17.8-18.4C576.7,311.9,584.7,303.8,594.6,303.8z M98.3,319l-36.9-37.1l-40.4,40.3
	l12.3,12.3c1.8,1.7,4.6,1.7,6.4,0l21.6-21.8l24.8,25l12.2-12.3C100.1,323.6,100.1,320.8,98.3,319z"
      />
      <path
        fill={fill}
        d="M111.1,281.9l-12.4,12.3c-1.7,1.8-1.7,4.6,0,6.4l21.6,21.6L95.6,347l12.3,12.2c1.8,1.7,4.6,1.7,6.4,0l37.1-37L111.1,281.9z"
      />
    </svg>
  );
}
