import React from 'react';

export default function SvgElectrolux({ fill }) {
  return (
    <svg id="svg2985" xmlns="http://www.w3.org/2000/svg" viewBox="158.5 367.7 998.9 227.7">
      <path
        id="path3363"
        fill={fill}
        d="M158.5 595.4h229.3V367.7H158.5v227.7zm147-210.3c40.6 13.2 69.8 51.3 69.9 96.4.1 44.4-28.6 82.4-69.9 96.5V385.1zm-32.4-5.2c7.3 0 15.1.9 21.6 2.3 0 51.6-50.3 94-102.1 94h-21.7c3.6-52.9 47.7-96.3 102.2-96.3m-80.5 107c51.9 0 102.1 42.4 102.1 94-6.5 1.3-14.7 2.3-21.6 2.3-54.6-.1-100.1-43.6-102.2-96.3h21.7z"
      />
      <path
        id="path3365"
        fill={fill}
        d="M464.6 501.5v-14.3h47.7v-18.3h-47.7v-26.6h50.8v-18.2h-70.3v76.8c0 19.4 8.3 32.7 27.8 35.4.4.1.8.1 1.1.1h.4c2 .2 4 .4 6.2.4h36.1v-18.2h-36c-6.2-.2-16.1-3.2-16.1-17.1"
      />
      <path id="path3367" fill={fill} d="M530.6 477.2v59.5h19.6V424h-19.6v53.2z" />
      <path
        id="path3369"
        fill={fill}
        d="M606.2 452.6c-24.7 0-43.2 16.9-43.2 43.2v.7c0 25.7 17.9 42.3 43.7 42.3 12.4 0 24-3.4 34.7-14.6l-11.8-11.4c-6.2 6.2-12.3 9.4-22.3 9.4-14.5 0-24.2-8.4-24.8-21.4h64.1c.3-3.2.3-6.8.3-6.8 0-23.1-16.2-41.4-40.7-41.4M582.9 487c.9-9.9 11-17.9 23.2-17.9 11.5 0 20.8 8.1 20.8 17.9h-44z"
      />
      <path
        id="path3371"
        fill={fill}
        d="M701.9 470c8.8 0 15.3 3.2 20.9 8.9l12.1-12.5c-9.5-10-20.8-14.1-33-14.1-24.9 0-44.9 18.3-44.9 43.3s19.9 43.4 44.8 43.4c12.2 0 23.5-4.1 33-14.2l-12.1-12.5c-5.6 5.7-12.1 9-20.9 9-14.4 0-25.4-10.9-25.4-25.7.1-14.7 11-25.6 25.5-25.6"
      />
      <path
        id="path3373"
        fill={fill}
        d="M766.6 501.5v-28.7h17.8v-17.7h-17.7v-31.2h-19.6v78.8c.6 18.3 8.9 30.8 27.8 33.4.4.1.8.2 1.2.2h.3c2 .2 4.1.4 6.2.4h2.1v-18.2h-2c-6.2-.1-16.1-3.1-16.1-17"
      />
      <path
        id="path3375"
        fill={fill}
        d="M820.2 467.3v-12.1h-19.4v81.5h19.4v-42.9c0-13.4 8.6-21.9 22.1-21.9 3.1 0 5.9.3 8.1.8v-18.8c-.8-.2-3.7-.5-5.8-.5-11.7.1-20.5 5.5-24.4 13.9"
      />
      <path
        id="path3377"
        fill={fill}
        d="M898.2 452.7c-24.9 0-44.8 18.8-44.8 43.2 0 24.4 19.8 43.2 44.8 43.2 24.7 0 44.8-18.8 44.8-43.2s-20-43.2-44.8-43.2m0 68.8c-14.5 0-25.4-11.2-25.4-25.6 0-14.4 10.9-25.6 25.4-25.6 14.4 0 25.4 11.2 25.4 25.6 0 14.4-11 25.6-25.4 25.6"
      />
      <path id="path3379" fill={fill} d="M955 475.2v61.4h19.6V423.9H955v51.3z" />
      <path
        id="path3381"
        fill={fill}
        d="M1045.1 502.5c0 12.9-7.6 18.4-17.9 18.4-10.2 0-17.9-5.5-17.9-18.2v-47.6h-19.7v49.2c0 23.3 17.1 34.5 37.4 34.5 20.5 0 37.8-11.3 37.8-34.9v-25.1-23.6h-19.7v47.3z"
      />
      <path
        id="path3383"
        fill={fill}
        d="M1138.7 510.6l-12.3-17.1 15.3-20.6 13-17.7h-21.3l-17.8 24.4-17.9-24.4h-21.2l12.1 16.5 16.2 22-17 23.5-14.1 19.6h21l20.8-28.8 20.9 28.8h21l-18.7-26.2z"
      />
    </svg>
  );
}
