import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import { motion } from 'framer-motion';
import { FormattedMessage } from 'gatsby-plugin-intl';
import '../../css/header.css';

import SvgAdb from '../../svg/SvgAdb';
import MenuIcon from '../../icons/MenuIcon';
import navItems from '../../data/nav-items';
import Language from '../Language';

const Header = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const splitUrl = window.location.href.split('#');
    if(splitUrl.length == 2){
      const nav = decodeURI(splitUrl[1]);
      navItems.forEach(navItem => {
        if(navItem.id == nav || navItem.text.split('.')[1] == nav){
          document.querySelector(`#${navItem.id}`).scrollIntoView({ behavior: 'smooth' });
          history.pushState(null, document.title, splitUrl[0]);
        }
      })
    }

    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <>
      <header
        className={`fixed w-full z-50 md:bg-opacity-90 transition duration-300 ease-in-out ${
          !top ? 'bg-white text-black blur shadow-lg' : 'adb-blue text-gray-200'
        }`}
      >
        <div className="container flex flex-col lg:flex-row justify-between items-center mx-auto py-2 xs:py-4 px-8">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <div className="flex items-center text-2xl">
              <div className="w-48 lg:w-64">
                <AnchorLink href="#hero" offset={() => 100}>
                  <SvgAdb fill={!top ? '#257a8f' : 'white'} />
                </AnchorLink>
              </div>
            </div>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <span>
                <MenuIcon />
              </span>
            </button>
          </div>
          <div className={`lg:flex flex-grow items-center${navbarOpen ? ' flex' : ' hidden'}`}>
            <Scrollspy
              items={navItems.map(item => item.id)}
              offset={-350}
              currentClassName={!top ? "text-primary" : ""}
              className="flex flex-col lg:flex-row list-none lg:ml-auto"
            >
              {navItems.map(item => (
                <motion.button whileTap={{ scale: 0.9 }} key="item">
                  <AnchorLink
                    className="px-3 py-2 flex items-center text-sm lg:text-base uppercase font-bold leading-snug hover:opacity-75"
                    style={{ color: 'inherit' }}
                    href={`#${item.id}`}
                    onClick={() => setNavbarOpen(false)}
                    offset={() => 100}
                  >
                    <FormattedMessage id={item.text} />
                  </AnchorLink>
                </motion.button>
              ))}
              <motion.button whileTap={{ scale: 0.9 }}>
                <Link
                  to="/career"
                  className="px-3 py-2 flex items-center text-sm lg:text-base uppercase font-bold leading-snug hover:opacity-75"
                >
                  <FormattedMessage id="career" />
                </Link>
              </motion.button>
              <div className="px-3 py-2 flex items-center">
                <Language />
              </div>
            </Scrollspy>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
