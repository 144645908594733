import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import BadgeCheckIcon from '../icons/BadgeCheckIcon';

export default function GridItem({ data }) {
  return (
    <div key={data} className="p-2 sm:w-1/2 w-full">
      <div
        className="bg-white text-primary rounded flex p-4 h-full items-center custom-box-shadow"
      >
        <BadgeCheckIcon className="w-8 h-8 flex-shrink-0 mr-4" />
        <span className="title-font font-medium">
          <FormattedMessage id={data} />
        </span>
      </div>
    </div>
  );
}
