import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

import LabelText from './LabelText';

export default ({ text, className = '' }) => {
  return (
    <LabelText className={`${className} text-2xl lg:text-4xl text-primary font-semibold`}>
      <FormattedMessage id={text} />
    </LabelText>
  );
};
