import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

const StatsBox = ({ primaryText, secondaryText }) => (
  <>
    <p className="text-4xl lg:text-6xl font-semibold text-primary">{primaryText}</p>
    <p className="font-semibold mb-6 uppercase">
      <FormattedMessage id={secondaryText} />
    </p>
  </>
);

export default StatsBox;
