import React from 'react';
import { FormattedMessage } from 'gatsby-plugin-intl';

export default ({ text }) => {
  return (
    <h2 className="text-lg lg:text-2xl font-bold text-gray-700">
      <FormattedMessage id={text} />
    </h2>
  );
};
