import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Footer from './Footer';
import Header from './Header';
import Helmet from '../Helmet';
import CookiePopup from '../CookiePopup';
import ScrollUpButton from '../ScrollUpButon';

// eslint-disable-next-line react/prop-types
const Layout = ({ children }) => {
  const intl = useIntl();
  return (
    <>
      <CookiePopup />
      <ScrollUpButton />
      <Helmet lang={intl.locale} />
      <Header />
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
