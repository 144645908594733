import React from 'react';
import { motion } from 'framer-motion';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FormattedMessage } from 'gatsby-plugin-intl';

import SvgAdb from '../../svg/SvgAdb';
import DoubleArrowIcon from '../../icons/DoubleArrowIcon';

const Hero = () => {
  return (
    <section id="hero" className="adb-blue">
      <div className="flex h-screen">
        <div className="m-auto">
          <div className="h-full">
            <motion.div
              initial={{ y: -26 * 1.2 }}
              animate={{ y: 0 }}
              transition={{ ease: 'easeOut', duration: 0.4 }}
            >
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none mx-auto px-6 lg:px-0"
                  style={{ maxWidth: "550px" }}    
              >
                <SvgAdb fill="white" />
              </h1>
              <p className="text-center text-xl lg:text-2xl mt-6 px-6 lg:px-0 font-light text-gray-200">
                <FormattedMessage id="slogan" />
              </p>
            </motion.div>
          </div>
          <div className="absolute bottom-0 left-0 w-full">
            <motion.div
              className="text-gray-200 text-center"
              animate={{ translateY: [0, 10, 0] }}
              transition={{ loop: Infinity, duration: 5 }}
            >
              <AnchorLink className="absolute" href="#aboutUs" style={{ bottom: '5rem' }}>
                <span>
                  <DoubleArrowIcon fill="white" />
                </span>
              </AnchorLink>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;